import React, { useState, useContext, useEffect, Fragment} from 'react'
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imagenes
import arrow from "../assets/img/arrow.svg"
import write from "../assets/img/write.svg"
import trash from "../assets/img/trash.svg"
import logo from "../assets/img/asociado.svg"
import mas from "../assets/img/mas.svg"
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";

//modulos
import Filtrado from './comun/Filtrado'
import Paginacion from './comun/Paginacion';
import AsociadoMod from './AsociadosMod';
import Avatar from './comun/Avatar';

const Asociados = () => {
	const {peticion, loc, perfil_admin, perfil_adm_asoc, perfil_adm_dojo, } = useContext(ConexContext) //contexto
	const { idReg, dojo, acc} = useParams();

	//STATES
	const [VerMod, setVerMod] = useState(0) //para ver el modal de ficheros
	const [List, setList] = useState([]) //listado
	const [NRes, setNRes] = useState(0) //nº de registros con filtro
	const [NTot, setNTot] = useState(0) //nº de registros sin filtro
	const [ListParams,setListParams]=useState({
		num:15,
		orden:'numAcae',
		filtro:'',
		ini:0,
		idDojo:0,
		nomDoj:'',
		cfAsoc:0,
		idReg:0
	})
	const [Cargando, setCargando] = useState(true) //Para el cargado 

	//atajos de teclado
	useHotkeys('alt+n', () => {if (perfil_admin | perfil_adm_asoc | perfil_adm_dojo) setVerMod(-1)})

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/asociados/del', {
			method: 'POST',
			json: {
				id: id
			}
		})
		if (pet?.estado??0===9){
			Swal.fire({
				title: loc("El asociado no se puede eliminar."),
				text: loc("El asociado tiene un historial asignado, por lo que no puede ser eliminado"),
				showConfirmButton: true,
				icon:'error',
			})
			return
		}
		if (pet.estado)//algún error 
			return;
	
		Swal.fire({
			title: "Asociado eliminado.",
			showConfirmButton: false,
			icon:'success',
			timer:1500
		})
		setListParams({...ListParams})
	}

	//cambia el orden
	const orden = (campo) => {
		//lo de idReg es para que se vuelva a hacer posicionamiento
		if (ListParams.orden === campo)
			setListParams({...ListParams,orden:campo + ' DESC',idReg:0})
		else
			setListParams({...ListParams,orden:campo,idReg:0})
	}


	useEffect(() => {
		//listado en sí de los asociados
		const listar = async () => {
	
			const pet = await peticion('/asociados/?'+
				`ini=${ListParams.ini}&`+
				`pag=${ListParams.num}&`+
				`orden=${ListParams.orden}&`+
				`filtro=${ListParams.filtro}&`+
				`idDojo=${ListParams.idDojo}`
			)
			if (pet?.estado??false)//algún error 
				return null;
			setList(pet.res)
			setNRes(pet.nRes)
			setNTot(pet.nTot)
			setCargando(false)
		}
		//peticiíon de nombre de dojo
		const getDojo = async(id)=>{
			const pet = await peticion('/dojos/ver?id=' + id)
			if (pet?.estado??false)//algún error 
				return ;
			setListParams((prev)=>({...prev,
				idDojo:pet?.res?.idDojo??id,
				cfAsoc:pet?.res?.cfAsoc??0,
				nomDoj:pet?.res?.nombre??''
			}))
		}
		//devuelve la pos de un id
		const getPosicion=async(id)=>{
			const pet = await peticion('/asociados/posicion?'+
			`id=${id}&`+
			`orden=${ListParams.orden}&`+
			`filtro=${ListParams.filtro}&`+
			`idDojo=${ListParams.idDojo}`)
			if (pet?.estado??false)//algún error 
				return ;
			if (pet?.res?.pos??false){
				const pos=pet.res.pos-(pet.res.pos-1)%ListParams.num-1
				if (pos!==ListParams.ini)
					setListParams((prev)=>({...prev,
						ini:pet.res.pos-(pet.res.pos-1)%ListParams.num-1,
						idReg:id
					}))
				else setListParams((prev)=>({...prev, idReg:id}))

			}
			else 
				listar()
		}
		let id=parseInt(dojo.split('_')[1])//dojo_xxx
		const idDojo=(isNaN(id)?0:id)//id del dojo de la url en número
		const idRegN=(isNaN(parseInt(idReg))?0:parseInt(idReg))//id del asoc de la url en número
		// console.log('idRegN',idRegN,ListParams.idReg);
		if (ListParams.idDojo!==idDojo)//hay un dojo nuevo en la url
			getDojo(idDojo);//peticion dojo y recaga de ListParams -> en la siguente pasaremos por listar
		else if (ListParams.idReg!==idRegN)// hay que listar un asociado concreto
			getPosicion(idRegN)
		else{
			setCargando(true)
			listar()
			if (acc==='mod')// se quiere modificar 
				setVerMod(idReg)

		}
	}, [ListParams,dojo,peticion,idReg,acc])


	// Comprobamos si es admin/adminDojo para mostrar u ocultar los campos
	const mostrarCampos = true//perfil_admin || perfil_adm_asoc|| perfil_adm_dojo === true

	return (
		<>
			<div className="col-xs-12">
				<div className="col-xs-9">
					<nav aria-label="breadcrumb" className='miga col-xs-12'>
						<ul className="breadcrumb col-xs-12">
							<li className="breadcrumb-item">
								<Link to={'/'}>
									{loc('Inicio')}
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">{loc('Asociados')}</li>
						</ul>
					</nav>
					<div className="col-xs-12 titularh2">
						<img src={logo} alt='dojo' />
						<h2><p>{loc('Asociados')}{(ListParams?.idDojo?? false) ? ` ${ loc('de')}: ${ListParams?.nomDoj ?? ''}`  : '' } </p></h2>
					</div>
				</div>
				<Avatar/>
			</div>

			<div className="col-xs-12 inline">
				<Paginacion
					nrPag={List.length}
					NRes={NRes}
					NTot={NTot}
					Ini={ListParams.ini}
					setListParams={setListParams}
					Num={ListParams.num}
					Filtro={ListParams.filtro}
				/>
				<Filtrado
					setListParams={setListParams}
					Filtro={ListParams.filtro}
				/>
			</div>


			<div className="contresto socios col-xs-12" >
				<div className="col-xs-12">
					<table border="0" className="tabladatos socios" style={{ borderCollapse: "collapse" }} >
						<tbody>
							<tr>
								<td style={{ width: '15%' }}>
									<button onClick={() => orden('numAcae')}>{loc('Nº ACAE')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'numAcae' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'numAcae DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '10%' }} className='ocultamovil'>
									<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'nombre' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'nombre DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '20%' }} className='ocultamovil'>
									<button onClick={() => orden('apellido')}>{loc('Apellidos')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'apellido' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'apellido DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '25%' }}>
									<button onClick={() => orden('mail')}>{loc('Correo')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'mail' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'mail DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '15%' }} className='ocultamovil'>
									<button onClick={() => orden('dojo')}>{loc('Dojo')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'dojo' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'dojo DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '5%' }} className='ocultamovil'>
									<button onClick={() => orden('edad')}>{loc('Tipo')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'edad' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'edad DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								{mostrarCampos && (
									<td style={{ width: '10%' }} className='ocultamovil'></td>
								)}
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-xs-12 scroll">
					<table border="0" className="tablaresultados socios" >
						<tbody>
							{List.map((elem) => (
								<Fragment key={elem.idAso}>
								<tr className={parseInt(elem.idAso) === parseInt(ListParams.idReg) ? 'selected' : ''}>
									<td style={{ width: '15%' }}>
										<img src={arrow} alt='arrow' className='arrow'/>
										<div className="photo_row">
											<img src={'/avatars/'+(elem.avatar??'default.jpg')} alt="fotoperfil" />
										</div>
										<span style={{paddingLeft:'25px'}}>{elem.numAcae}</span>
									</td>
									<td style={{ width: '10%' }} className='ocultamovil'>{elem.nombre}</td>
									<td style={{ width: '20%' }} className='ocultamovil'>{elem.apellido}</td>
									<td style={{ width: '25%' }}><p>{elem.mail}</p>
										<div className='detalles'>
												<button><img src={despliega} alt="despliega"/></button>
												<button><img src={pliega} alt="pliega" /></button>										
										</div>	
									</td>
									<td style={{ width: '15%' }} className='ocultamovil'>{elem.dojo}</td>
									<td style={{ width: '5%' }} className='ocultamovil'>{
										elem.edad==='A'?
										loc('Adulto')
										:
										loc('Niño')
									}</td>
									{mostrarCampos && (
										<td style={{ width: '10%' }} className='ocultamovil'>
											<ul>
												<li>
													<button>
														<span>
															<img src={write} alt='write' onClick={() => setVerMod(elem.idAso)} />
														</span>
													</button>
												</li>
												{(perfil_admin || perfil_adm_asoc || perfil_adm_dojo) &&
													<li>
														<button>
															<span>
																<img src={trash} alt='trash' onClick={() => {
																	Swal.fire({
																		title: "¿Seguro que quieres eliminar el asociado?",
																		showCancelButton: true,
																		confirmButtonText: "Sí",
																		icon:'question',
																		cancelButtonText: 'No'
																	}).then((result) => {
																		if (result.isConfirmed) borrar(elem.idAso)
																	});
																}} />
															</span>
														</button>
													</li>
												}
											</ul>
										</td>
									)}
								</tr>
								<tr className='borde_gris'></tr>
								<tr className='listaoculta'>
									<td colSpan={6}>
										<ul>
											<li>{elem.nombre}</li>
											<li>{elem.apellido}</li>
											<li>{elem.dojo}</li>
											<li>{
												elem.edad==='A'?
												loc('Adulto')
												:
												loc('Niño')
											}</li>
											<li>
												
												
													
											
													<button>
														<span>
															<img src={write} alt='write' onClick={() => setVerMod(elem.idAso)} />
														</span>
													</button>
											
												{(perfil_admin || perfil_adm_asoc || perfil_adm_dojo) &&
													
														<button>
															<span>
																<img src={trash} alt='trash' onClick={() => {
																	Swal.fire({
																		title: "¿Seguro que quieres eliminar el asociado?",
																		showCancelButton: true,
																		confirmButtonText: "Sí",
																		icon:'question',
																		cancelButtonText: 'No'
																	}).then((result) => {
																		if (result.isConfirmed) borrar(elem.idAso)
																	});
																}} />
															</span>
														</button>
													
											
												}
											</li>
										</ul>
									</td>
								</tr>
								</Fragment>
							))

							}
						</tbody>
					</table>
				</div>
			</div>

			<div>
				{(perfil_admin | perfil_adm_asoc | perfil_adm_dojo)?
					<button 
						className="btred" 
						title="ALT+N"
						onClick={() => setVerMod(-1)}>
						{ListParams?.cfAsoc ?? false ?
								loc('Añadir nuevo asociado a: ') + (ListParams?.nomDoj ?? '')
								:
								loc('Añadir nuevo asociado')
						}
						<span><img src={mas} alt='mas' /></span>
					</button>
					: null
				}
			</div>

			<AsociadoMod
				show={VerMod}
				ListParams={ListParams}
				setListParams={setListParams}
				onHide={() => setVerMod(0)}
			/>
		</>
	)
}
export default Asociados