import React, {  } from 'react'


const Validaciones = () => {



    return (
        <>

        </>
    )
}

export default Validaciones