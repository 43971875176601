	/**
	* Funcion para ayudar validar un campo en una sola línea
	* @param {boolean} cond condición si debe dar error
	* @param {function} error funcion setter del mensaje de error
	* @param {string} text texto si hay error
	* @returns {boolean}
	*/

	export default function validErr(cond,error,text){
		if (cond)
			error(text)
		return(cond)
	}
