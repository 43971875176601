import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import ConexContext from '../context/conex/ConexContext'
import '../assets/css/acae.css';

//IMGs
import logo from  "../assets/img/inicio.svg"
import users from  "../assets/img/users.svg"
import dojo from  "../assets/img/dojo.svg"
import edit from  "../assets/img/edit.svg"
import Avatar from './comun/Avatar';

import flor from  "../assets/img/flor.svg"


export const Inicio = () => {
	const { 
		peticion, 
		loc,
		perfil_id, 
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_asoc,
		perfil_dojo
	} = useContext(ConexContext) // Contexto
	const { lang } = useParams();

	const [nombreCompleto, setNombreCompleto] = useState('') // Estado para almacenar el nombre del asociado
	const [Asociaciones,setAsociaciones]=useState([])//clase con las asociaciones
	const [Dojos,setDojos]=useState([])//clase con los dojos
	const [Cuotas,setCuotas]=useState([])//cuotas pagadas
	
	const colCuotas=[
		'rgb(22 162 147)',
		'rgb(168 0 59)',
		'rgb(224 147 30)',
		'rgb(48 122 180)',
		'rgb(40 158 105)',
		'rgb(183 53 53)',
		'rgb(214 59 113)',
		'rgb(44 184 211)',
		'rgb(48 158 40)',
		'rgb(199 106 0)',
		'rgb(121 18 54)',
		'rgb(106 122 204)',
		'rgb(22 190 77)',
		'rgb(203 63 63)',
		'rgb(168 0 158)',
		'rgb(65 128 199)',
		'rgb(35 208 113)',
		'rgb(228 24 24)',
		'rgb(188 83 132)',
		'rgb(87 64 196)'
	]
	//useEffect
	useEffect(() => {
		const ver = async () => {
			//Nombre asociado
			peticion('/asociados/ver?id=' + perfil_id).then(
				(pet)=>{if (pet.estado===0)	setNombreCompleto(pet.res.nombreCompleto)})
			// descargo las asociaciones
			peticion('/asocs/?ini=0&pag=1000&orden=nombre&filtro=').then(
				(pet)=>{if (pet.estado===0)	setAsociaciones(pet?.res??[])})
			//descargo los dojos de la asociación que tenga en el perfil
			peticion('/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc='+perfil_asoc).then(
				(pet)=>{if (pet.estado===0)	setDojos(pet?.res??[])})
			//cuotas del asociado
			peticion('/cuotas/listCuotas?idAsoc=' + perfil_id).then(
				(pet)=>{if (pet.estado===0) setCuotas(pet?.res??[])})
			}
		ver()
	}, [perfil_id,perfil_asoc, peticion])
	
	//Para cambiar el perfil de adm de dojo
	const cambPerfil= async (id,tipo) => {
		if (id==='') return;
		await peticion(`/modPerfil?id=${id}&tipo=${tipo}`)

	}

	return (
			<>
			<div className="col-xs-12">
				<div className="col-xs-9">
					<nav aria-label="breadcrumb" className='miga col-xs-12'>
						<ul className="breadcrumb col-xs-12">
							<li className="breadcrumb-item active"  aria-current="page">
								{loc('Inicio')}
							</li>
						</ul>
					</nav>
					<div className="col-xs-12 titularh2">
						<img src={logo} alt='asoc' />
						<h2>{loc('Inicio')}</h2>
					</div>
				</div>
				<Avatar/>
			</div>
			
					<div className="col-xs-12 nomreasoc continput inicio">
						<div>
								<h3>{loc('Bienvenido a la app de ACAE') + ', '}
								<Link to={`/${lang}/asociados/dojo_0/${perfil_id}/mod`}>
									{nombreCompleto} <img src={edit} alt="edit" className='edit'/>
								</Link>
								</h3>
						</div>
						{!perfil_admin&&!perfil_adm_asoc&&!perfil_adm_dojo?null:
						<>
						<hr></hr>
							<div className="col-xs-12 listaselects">
								<h3>
									<p>Tu perfil tiene permisos de:</p>
									{perfil_admin&&<button className='btnlista active no-clikable'>Administrador</button>}
									{perfil_adm_asoc&&<button className='btnlista active no-clikable'>Adm. Asociación</button>}
									{perfil_adm_dojo&&<button className='btnlista active no-clikable'>Admin. Dojo</button>}

								</h3>
								<ul>
									{!perfil_admin&&!perfil_adm_asoc?null:
										<li className='col-xs-5' >
											<p><img src={users} alt="Asociación"/> Asociación:</p>
											<select 
												
												value={perfil_adm_asoc?perfil_asoc:''} 
												onChange={(el)=>{cambPerfil(el.target.value,'asoc')}}
												disabled={!perfil_admin} >
													{perfil_adm_asoc?null:<option value='' key='_'>No eres administrador de asociación, pero puedes cambiar tu perfil aquí.</option>}
													{Asociaciones.map((elem)=>(<option value={elem.idAsoc} key={elem.idAsoc}>{elem.nombre} ({elem.zona})</option>))}
											</select>
										</li>
									}
									<li className='col-xs-5' >
										<p><img src={dojo} alt="dojo"/>Dojo:</p>
										<select 
											onChange={(el)=>{cambPerfil(el.target.value,'dojo')}}
											value={perfil_adm_dojo?perfil_dojo:''}
											disabled={!perfil_adm_asoc&&!perfil_admin} >
											{perfil_adm_dojo?null:<option value='' key='_'>No eres administrador de dojo, pero puedes cambiar tu perfil aquí.</option>}
											{Dojos.map((elem)=>(<option value={elem.idDojo} key={elem.idDojo}>{elem.nombre}</option>))}
										</select>
									</li>
								</ul>
							</div> 
						</>
						}
						<hr />
						<div className="col-xs-12 listaselects2">
							<div className="col-xs-12 lista fechas">
							{Cuotas.length>0?
								<div className="col-xs-12">
									<h3>
										Tienes las siguientes cuotas pagadas, y validadas por la secretaría del ACAE: 
									</h3>
									<ul>
										{Cuotas.map((el)=>(
											<li key={el}>
											
												<p style={{ background:colCuotas[el%20] }}>{el}</p>
												<img src={flor} alt=""/>
											</li>
										))}
									</ul>

							
								</div>
							:null}
							</div>
						</div> 
					</div>
			</>
	)
}
