import React, {useContext,useEffect,useState,useRef} from 'react'
import {
	Link,
	Outlet,
	useParams,
	useNavigate
} from "react-router-dom";

//imagenes
import logo from "../assets/img/logo.jpg"
import users from "../assets/img/users.svg"
import dojo from "../assets/img/dojo.svg"
import location from "../assets/img/location.svg"
import check from "../assets/img/check.svg"
import data from "../assets/img/data.svg"
import inicio from "../assets/img/inicio.svg"
import curso from "../assets/img/curso.svg"
import cuota from "../assets/img/cuote.svg"
import asociado from "../assets/img/asociado.svg"
import loading from "../assets/img/loading.gif";

import espanol from '../assets/img/espanol.png';
import ingles from '../assets/img/uk.png';
import frances from '../assets/img/frances.png';

import clickOut from '../assets/js/clickOut'
//Contexto
import ConexContext from "../context/conex/ConexContext";

const Layout = ({WCuotas,setTimer}) => {
	const navigate = useNavigate()
	const { lang } = useParams();
	const selLang = useRef();
	const [OpLang, setOpLang] = useState(false);
	const {
		setLocale,
		locale,
		loc,
		perfil_admin,
		perfil_adm_dojo,
		perfil_adm_asoc,
		perfil_dojo,
		Cargando,
	}=useContext(ConexContext)//contexto

	//FUNCIONES
	//cierra el selec de lenguaje
	clickOut(selLang, () => {
		setOpLang(false)
	});
	//Abrir y cerrar barra lateral
	const toggleSidebar = () => {
		document.getElementById('sidebar').classList.toggle('active');
		document.getElementById('content').classList.toggle('active');
		document.getElementById('sidebaricon').classList.toggle('cambiaicono');
	}
	useEffect(() => {
		setLocale(lang);
	}, [lang,setLocale])

	useEffect(() => {
		setTimer(0)
	}, [setTimer]);

	// Comprobamos si es cualquier tipo de admin para mostrar u ocultar los campos
	const mostrarCampos = perfil_admin || perfil_adm_asoc || perfil_adm_dojo === true


if (!locale)
	return (null)
else
	return (
<div id="wrapper">
	{Cargando===true && <div className="precarga"><img src={loading} alt="cargando"/></div>}
	<div className="dashboard wrapper">
		<nav className="col-xs-12 navbar navbar-expand-lg  navbarmovil">
			<div className="container-fluid">
				<div className="contheader">
						<div className="intheader">
							<button className="navbar-brand" href="#"><img src={logo} className="logo" alt='logo'/></button>
							<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						</div>
				</div>

				{/* select móvil, abajo de layout tienes otro select como éste para escritorio: */}
				<div className='col-xs-12 fixborder'>
						<div className="selectidiomas escritorio abierto" id="button">
							<h3>Idioma:</h3>
							<input id="toggle" type="checkbox" />
							<div id="button">
								<div id="two">
								<ul>
									<li><button><img src={espanol} alt=""/><p>España</p></button></li>
									<li><button><img src={ingles} alt=""/><p>Inglés</p></button></li>
									<li><button><img src={frances} alt=""/><p>Francés</p></button></li>
								</ul>
								</div>
							</div>
						</div>
				</div>


				<div className="collapse " id="navbarNav">

				<ul className="navbar-nav">
					<li className="nav-item">
						<Link to={{pathname:`/${lang}`}}><button>{loc('Inicio')}<span><img src={inicio} alt='Inicio'/></span></button></Link>
					</li>
					<li className="nav-item">
						<Link to={{pathname:`/${lang}/asociaciones`}}><button>{loc('Asociaciones')}<span><img src={location} alt='asociaciones'/></span></button></Link>
					</li>
					<li className="nav-item">
						<Link to={{pathname:`/${lang}/dojos/asoc_`}}><button>{loc('Dojos')}<span><img src={dojo} alt='dojos' /></span></button></Link>
					</li>
					<li className="nav-item">
						{!perfil_admin & !perfil_adm_asoc?
							<Link to={{pathname:`/${lang}/asociados/dojo_${perfil_dojo}`}}>
								<button>{loc('Asociados')}<span><img src={users} alt='asociados' /></span></button>
							</Link>
						:
							<Link to={{pathname:`/${lang}/asociados/dojo_`}}>
								<button>{loc('Asociados')}<span><img src={users} alt='asociados' /></span></button>
							</Link>
						}
					</li>
					{mostrarCampos && (
						<li className="cuotasli">
							<Link to={{pathname:`/${lang}/cuotas`}}>
								<button>{loc('Cuotas')}<span><img src={cuota} alt='cuotas' /></span></button>
							</Link>
							<button><span className='aviso'><p>1</p></span></button>
						</li>
					)}
					<li>
						{/* <button>{loc('Validaciones')}<span><img src={check} alt='check' /></span></button> */}
						<Link to={{pathname:`/${lang}/validaciones`}}><button>{loc('Validaciones')}<span><img src={check} alt='check'/></span></button></Link>
					</li>
					<li>
						<Link to='/'><button>{loc('Estadísticas')}<span><img src={data} alt='data' /></span></button></Link>
					</li>
				</ul>
				</div>
			</div>
		</nav>  

		
		<div className="col-xs-2 active oculta" id='sidebar'>

			 <nav id="sidebaricon" className="navbar navbar-expand-lg cambiaicono oculta">
				<div className=" alignderecha">
					<button type="button" id="sidebarCollapse" className="btn" onClick={toggleSidebar}></button>   
				</div>
			</nav>

			<div className="nav oculta col-xs-12">
				<img src={logo} className="logo" alt='logo'/>
				<ul>
					<li>
						<Link to={{pathname:`/${lang}`}}><button>{loc('Inicio')}<span><img src={inicio} alt='Inicio'/></span></button></Link>
					</li>
					<li>
						<Link to={{pathname:`/${lang}/asociaciones`}}><button>{loc('Asociaciones')}<span><img src={users} alt='asociaciones'/></span></button></Link>
					</li>
					<li>
						<Link to={{pathname:`/${lang}/dojos/asoc_`}}><button>{loc('Dojos')}<span><img src={dojo} alt='dojos' /></span></button></Link>
					</li>
					<li>
						{!perfil_admin & !perfil_adm_asoc?
							<Link to={{pathname:`/${lang}/asociados/dojo_${perfil_dojo}`}}>
								<button>{loc('Asociados')}<span><img src={asociado} alt='asociados' /></span></button>
							</Link>
						:
							<Link to={{pathname:`/${lang}/asociados/dojo_`}}>
								<button>{loc('Asociados')}<span><img src={asociado} alt='asociados' /></span></button>
							</Link>
						}
						
					</li>
					{mostrarCampos && (
						<li>
							<Link to={{pathname:`/${lang}/cuotas`}}>
								<button>{loc('Cuotas')}<span><img src={cuota} alt='cuotas' /></span></button>
							</Link>
							{WCuotas!==0 ?
								<button><span className='aviso'><p>{WCuotas}</p></span></button>:null
							}
						</li>
					)}
					
					{/* <li>
						<Link to={{pathname:`/${lang}/cursos`}}><button>{loc('Cursos')}<span><img src={curso} alt='cursos' /></span></button></Link>
					</li> */}
					{/* <li>
						<Link to='/'><button>{loc('Estadísticas')}<span><img src={data} alt='data' /></span></button></Link>
					</li> */}
				</ul>
			</div>

			{/* select idiomas escritorio */}

			<div className={`selectidiomas ${OpLang?'abierto':'cerrado'}`} onClick={()=>setOpLang(!OpLang)} id="button"  ref={selLang} >
				<h3><span>Idioma:</span>{
						lang==='es' ?  <img src={espanol} alt=""/>
						:lang==='en'? <img src={ingles} alt=""/>
						:lang==='fr'? <img src={frances} alt=""/>
						:null
						}
				</h3>
				<input id="toggle" type="checkbox" />
				<div id="button">
					<div id="two">
						<ul>
							<li className={lang==='es'?'selec':''}><button onClick={()=>navigate('/es')}><img src={espanol} alt=""/><p>Español</p></button></li>
							<li className={lang==='en'?'selec':''}><button onClick={()=>navigate('/en')}><img src={ingles} alt=""/><p>Inglés</p></button></li>
							<li className={lang==='fr'?'selec':''}><button onClick={()=>navigate('/fr')}><img src={frances} alt=""/><p>Francés</p></button></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div className="active" id='content'>
			<Outlet/>
		</div>
	</div>
</div>

	)
}

export default Layout