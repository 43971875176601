import React, {useState,useRef,useContext,useEffect} from 'react';
import {useNavigate,useParams} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';

import validErr from '../assets/js/valida'

import '../assets/css/modal.css';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imágenes
import close2 from "../assets/img/close2.svg"
import locationmas from "../assets/img/cuote.svg"
import save from "../assets/img/save.svg"
import desc from "../assets/img/desc_gris.svg"
import asc from "../assets/img/asc_gris.svg"
import arrow from "../assets/img/arrow.svg"
import nopin from "../assets/img/pin-01.svg";
import pin from "../assets/img/pin-02.svg";


const RemesasMod = (props) => {
	const {peticion,loc}=useContext(ConexContext)
	const navigate = useNavigate();
	const {lang} = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guarda,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})
	useHotkeys('esc', cierra,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})

	//STATES
	const [Filtro,setFiltro]=useState('')// Filtro
	const [Anio,setAnio]=useState('')// selec de años
	const [NumAd,setNumAd]=useState(0)// cuotas de adultos
	const [NumNi,setNumNi]=useState(0)// cuotas de niños
	const [ListParams,setListParams]=useState({
		orden:'numAcae',
		anio:'',
		selec:[],
		recarga:false
	})
	const [List,setList]=useState([])// listo alumnos
	const [Cargando, setCargando] = useState(false) //Para el cargado 
	const [Lectura, setLectura] = useState(false) //está en modo lectura

	
	const [ErrAnio,setErrAnio]=useState('')// Error en el nombre
	const [ErrList,setErrList]=useState('')// Error en el listado

	//REFs
	const rAnio = useRef();	
	const rNumAd = useRef();
	const rNumNi = useRef();
	const rFiltr = useRef();

	//quita o pone un elemento del Select 
	const toggleSelec = ( val) =>{
		if (Lectura) return
		const a=[...ListParams.selec]
		var index = a.indexOf(val);
		if (index === -1) 
			a.push(val);
		else
			a.splice(index, 1);
		setListParams((prev)=>({...prev,selec:a}))
	}
	//EFFECT
	useEffect(() => {
		async function ver(){
			setCargando(true)
			const pet=await peticion('/cuotas/ver?id='+ props.show)
			if (pet.estado)//algún error 
				return;
			setCargando(false)
			setListParams((prev)=>({...prev,
				selec:pet.res.selec,
				anio:pet.res.anio,
				recarga:true
			}))
			rAnio.current.focus()
			if (pet.res.cfValDojo!==0) setLectura(true)
		}
		// console.log('props.show',props.show);
		if (props.show>0) 
			ver()
		else if (props.show<0){
			setAnio('')
			setNumAd(0)
			setNumNi(0)
			setListParams((prev)=>({...prev,
				anio:'',
			}))
			rAnio.current.focus()
		}
	}, [props.show,peticion])
	// useEffect(() => {console.log('List',List)}, [List]);
	// ha cambiado el año: hay que consultar los alumnos
	useEffect(() => {
		async function listAlum(){
			setCargando(true)
			const pet=await peticion(`/cuotas/listAlum?anio=${Anio}&orden=${ListParams.orden}&id=${props.show}`)
			if (pet?.estado??true)//algún error 
				return;
			setCargando(false)
			setList(pet?.res??[])
			setListParams((prev)=>({
				...prev,
				recarga:false
			}))
		}
		if (ListParams.recarga && Anio!==ListParams.anio){//Se quiere cargar el listado 
			setAnio(ListParams.anio)
			return;//se volverá a llegar y ya listar directamente
		}
		if (Anio===''){//el usuario ha seleccionado sin año
			setList([])
			return;
		}
		if (Anio!==ListParams.anio)
			setListParams((prev)=>({
				...prev,
				selec:[],
				anio:Anio,
				recarga:true
			}))
		else if (ListParams.recarga)
			listAlum()
		
	}, [Anio,ListParams,peticion,props.show])
	useEffect(() => {
		if (props.show===0) return
		let nA=0
		let nN=0
		List.forEach(e => {
			if (ListParams.selec.includes(e.idAso)){
				if (e.tipo==='A') nA++
				else nN++
			}
		});
		setNumAd(nA)
		setNumNi(nN)
	}, [List,ListParams.selec,props.show]);
	// guardar el registro
	async function guarda(){
		let error=false
		//requeridos
		error|=validErr(Anio==='',setErrAnio,loc('El año es requerido.'))
		error|=validErr(List.length>0 && ListParams.selec.length===0,setErrList,loc('Debes marcar al menos un asociado.'))
		// filtros sin necesidad de peticiones
		if (error)//algún error
			return;
		let pet
		if (props.show<0)//inserción
			pet=await peticion('/cuotas/ins',{method:'POST',json:{
				anio:Anio,
				selec:ListParams.selec,
			}})
		else if (props.show>0)//modificación
			pet=await peticion('/cuotas/mod',{method:'POST',json:{
				id:props.show,
				anio:Anio,
				selec:ListParams.selec,
			}})
		let id=pet?.res?.id??props.show
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev)=>({...prev}))
		else{
			navigate(`/${lang}/cuotas/${id}`)
		}
		
	}
	//cierra el modal
	function cierra(){
		setErrAnio('')
		setErrList('')
		setList([])
		setAnio('')
		setLectura(false)
		props.onHide()
	}
	//cierra el modal
	function orden(campo){
		if (ListParams.orden === campo)
			campo=campo + ' DESC'
		setListParams({
			...ListParams,
			orden:campo,
			recarga:true
		})
	}
	

	if (props.show===0)
		return("")
	else
		return (
<main className="custom-modal modalasocs modalcuotas">
	<div className="modal-main" >
		<div className="container_modal col-lg-8 col-md-12 d-flex">
			<div className="nuevazona col-xs-12">
				<div className="col-xs-12 d-flex">
					<h4 className="col-xs-12">
						<img src={locationmas} alt='cerrar' title='cerrar'/> {props.show<0?loc('Añadir nueva asociación'):loc('Modificar asociación')}
					</h4>
					<button type="button" onClick={cierra} className="btn cerrarmodal" alt="cerrar"><span><img src={close2} alt='cerrar' title='cerrar'/></span></button>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Año')}:</p> 
						<select 
							disabled={Lectura}
							ref={rAnio}
							value={Anio}
							onChange={() => {setErrAnio('');setAnio(rAnio.current.value)}}>
							<option value={''}>{loc('Seleccionar año')}</option>
							{Array(50).fill(new Date().getFullYear()).map(
								(elem,i)=>(
									<option value={elem-i} key={i}>{elem-i}</option>
								))
							}
						</select>
					<p className='error'>{ErrAnio}</p>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Número de cuotas de adulto')}:</p>
					<input 
						style={{textAlign:'right'}}
						disabled
						ref={rNumAd}
						value={NumAd}
					/>
					<p className='error'></p>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Número de cuotas de niños')}:</p>
					<input 
						disabled
						style={{textAlign:'right'}}
						ref={rNumNi}
						value={NumNi}
					/>
					<p className='error'></p>
				</div>
				<div className="col-xs-12 continput">
					<input 
						ref={rFiltr}
						placeholder='Filtrar'
						onChange={()=>setFiltro(rFiltr.current.value)}
						value={Filtro}
						/>
					<p className='error'></p>
				</div>
				<div className="contresto col-xs-12" >

				<div className="col-xs-12 ">

					<div className='col-xs-12 divtabla'>
						<table border="0" className="tablaejem tabladatos" style={{ borderCollapse: "collapse" }}>
							<thead>
								<tr key="cabeceradatostabla" style={{ width: '100%' }}>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('numAcae')}>{loc('Núm. ACAE')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'numAcae' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'numAcae DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'nombre' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'nombre DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '40%' }}>
										<button onClick={() => orden('apellido')}>{loc('Apellidos')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'apellido' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'apellido DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '10%' }}>
									
										<button onClick={() => orden('tipo')}>{loc('Tipo')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'tipo' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'tipo DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									{!Lectura&&
										<th style={{ width: '10%',textAlign:'right' }}>
											<button disabled onClick={() => orden('numAcae')}>{loc('Selecciona')}</button>
										</th>
									}
								</tr>
							</thead>
						</table>
					</div>
				</div>
				<div className="col-xs-12 scroll">
					<table border="0" className="tablaresultados" style={{ borderCollapse: "collapse" }}>
						<tbody>
							{List
								.filter((elem)=>(` ${elem?.numAcae??''} ${elem?.nombre??''} ${elem?.apellido??''}`.toUpperCase().includes(Filtro.toUpperCase())))
								.map((elem) => ((Lectura & !ListParams.selec.includes(elem.idAso))?null :
								<tr key={elem.idAso} className={'alumn'+(elem.selec?'':(ListParams.selec.includes(elem.idAso)?'Act':'Des'))} onClick={()=>{if (!elem.selec) toggleSelec(elem.idAso)}}>
									<td style={{ width: '20%' }}>
										<img src={arrow} alt='arrow' className='arrow'/>
										<div className="photo_row">
											<img src={'/avatars/'+(elem.avatar??'default.jpg')} alt="fotoperfil" />
										</div>
										<span style={{paddingLeft:'25px'}}>{elem.numAcae}</span>
									</td>
									<td style={{ width: '20%' }}>{elem.nombre}</td>
									<td style={{ width: '40%' }}>{elem.apellido}</td>
									<td style={{ width: '10%' }}>
									{elem.tipo==='A'?
										loc('Adulto')
										:
										loc('Niño')
									}
									</td>
									{!Lectura&&
									<td style={{ width: '10%' }}>
										<div  className="contpin">
											{elem.selec? loc('Ya remesado') : ListParams.selec.includes(elem.idAso)?
												<img src={pin} alt="" className='imgpin'/>
												:
												<img src={nopin} alt="" className='imgpin'/>
											}
										</div>
									</td>
									}
								</tr>
							))
							}
						</tbody>
					</table>
					

				</div>
				<div className="col-xs-12 ">
					<p className='error'>{ErrList}</p>
				</div> 
				</div> 

				{!Lectura &&
				<div className="col-xs-12 contbutton">
					<button type="button" onClick={guarda} className="btn btnguardar ">{loc('Guardar')} <span><img src={save} alt='guardar' title='guardar'/></span></button>
					<p className='error'></p>
				</div>
				}
			</div>
		</div>
	</div>
</main>

		);
}
 
export default RemesasMod;