import React, {useContext} from 'react'
import {
	Link,
	useParams
} from "react-router-dom";

//Contexto
import ConexContext from "../../context/conex/ConexContext";

import cerrarsesion from "../../assets/img/cerrarsesion.svg"

const Avatar = () => {
	const { lang } = useParams();
	const {
		loc,
		setToken,
		perfil_avatar
	}=useContext(ConexContext)//contexto

	return (
	<div className="col-xs-3">
		<div className="col-xs-12 contphoto">
			<div className="photo">
			<Link to={{pathname:`/${lang}`}}><button><span><img src={'/avatars/'+(perfil_avatar??'default.jpg')} alt='avatar' /></span></button></Link>
			</div>
		</div>
		<button onClick={() => setToken('')} className="cerrarsesion">
			{loc('Cierra sesión')}<span><img src={cerrarsesion} alt='cerrar sesión' /></span>
			</button>
	</div>
	)
}

export default Avatar