import React, {useContext,useRef,useEffect} from 'react'

//Contexto
import ConexContext from "../../context/conex/ConexContext";
//imagenes
import search from "../../assets/img/search.svg"


const Filtrado = ({setListParams,Filtro}) => {
	const {loc}=useContext(ConexContext)//contexto
	const filtro=useRef()
	useEffect(() => {
		filtro.current.value=Filtro
	}, [Filtro])
	
	return (
		<>
		<div className="nopadding search">
				<form onSubmit={(event)=>{event.preventDefault();setListParams((prev)=>({...prev,
						filtro:filtro.current.value,
						ini:0
					}))}}>
					<input ref={filtro} type="search" placeholder={loc("Buscar")} defaultValue={Filtro} 
						onBlur={()=>{/*/setListParams((prev)=>({...prev,filtro:filtro.current.value}))*/}}></input>
					<img src={search} alt='' title=''/>
				</form>
			</div>
		</>
	)
}

export default Filtrado