import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';
import moment from 'moment';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imagenes
import arrow from "../assets/img/arrow.svg"
import write from "../assets/img/write.svg"
import trash from "../assets/img/trash.svg"
import logo from "../assets/img/curso.svg"
import mas from "../assets/img/mas.svg"
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";
//modulos
import CursosMod from './CursosMod'
import Filtrado from './comun/Filtrado'
import Paginacion from './comun/Paginacion';
import Avatar from './comun/Avatar';


const Cursos = () => {
	const { 
		peticion, 
		loc, 
		perfil_admin, 
		perfil_adm_asoc, 
		perfil_adm_dojo, 
		perfil_asoc
	} = useContext(ConexContext)//contexto
	const { idReg, asoc, lang } = useParams();

	//STATES
	const [Detalles, setDetalles] = useState(0)// id del que se debe mostrar detalles
	const [VerMod, setVerMod] = useState(0)// para ver el modal de Ficheros
	const [List, setList] = useState([])// listado
	const [NRes, setNRes] = useState(0)// Nº de registros con filtro
	const [NTot, setNTot] = useState(0)// Nº de registros con filtro
	const [ListParams,setListParams]=useState({
		num:15,
		orden:'fecha DESC',
		filtro:'',
		ini:0,
		nomAso:'',
		idAsoc:0,
		idReg:0
	})
	const [Cargando, setCargando] = useState(false) //Para el cargado 

	//atajos de teclado
	useHotkeys('alt+n', () => {if (perfil_admin | perfil_adm_asoc|perfil_adm_dojo ) setVerMod(-1)})


	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/dojos/del', {
			method: 'POST', json: {
				id: id
			}
		})
		if (pet?.estado??0===9){
			Swal.fire({
				title: loc("El dojo no se puede eliminar."),
				text: loc("El dojo tiene asociados asignados, por lo que no puede ser eliminado"),
				showConfirmButton: true,
				icon:'error',
			})
			return
		}
		if (pet?.estado??true)//algún error 
			return;
		Swal.fire({
			title: loc("Dojo eliminado."),
			showConfirmButton: false,
			icon:'success',
			timer:1500
		})
		setListParams({...ListParams})
	}

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({...ListParams,orden:campo + ' DESC'})
		else
			setListParams({...ListParams,orden:campo})
	}

	//EFFECT
	useEffect(() => {
			//listado en sí de los asociados
			const listar = async () => {
	
				const pet = await peticion('/cursos/?'+
					`ini=${ListParams.ini}&`+
					`pag=${ListParams.num}&`+
					`orden=${ListParams.orden}&`+
					`filtro=${ListParams.filtro}`
				)
				if (pet?.estado??false)//algún error 
					return null;
				setList(pet.res)
				setNRes(pet.nRes)
				setNTot(pet.nTot)
				setCargando(false)
			}
			//devuelve la pos de un id
			const getPosicion=async(id)=>{
				const pet = await peticion('/dojos/posicion?'+
				`id=${id}&`+
				`orden=${ListParams.orden}&`+
				`filtro=${ListParams.filtro}&`+
				`idAsoc=${ListParams.idAsoc}`)
				if (pet?.estado??false)//algún error 
					return ;
				if (pet?.res?.pos??false){
					const pos=pet.res.pos-(pet.res.pos-1)%ListParams.num-1
					if (pos!==ListParams.ini)
						setListParams((prev)=>({...prev,
							ini:pet.res.pos-(pet.res.pos-1)%ListParams.num-1,
							idReg:id
						}))
					else setListParams((prev)=>({...prev, idReg:id}))
	
				}
			}
			const idRegN=(isNaN(parseInt(idReg))?0:parseInt(idReg))//id del asoc de la url en número
			// console.log('idRegN',idRegN,ListParams.idReg);
			setCargando(true)
			if (ListParams.idReg!==idRegN)// hay que listar un asociado concreto
				getPosicion(idRegN)
			else{
				listar()
			}
	}, [ListParams,peticion,idReg])

	// Comprobamos si es admin/adminDojo para mostrar u ocultar los campos
	const mostrarCampos = perfil_admin || perfil_adm_asoc === true

	return (
		<>
			<div className="col-xs-12">
				<div className="col-xs-9">
					<nav aria-label="breadcrumb" className='miga col-xs-12'>
						<ul className="breadcrumb col-xs-12">
							<li className="breadcrumb-item">
								<Link to={'/'}>
										{loc('Inicio')}
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">{loc('Cursos')}</li>
						</ul>
					</nav>
					<div className="col-xs-12 titularh2">
						<img src={logo} alt='asoc' />
						<h2>{loc('Cursos')}</h2>
					</div>
				</div>
				<Avatar/>
			</div>

			<div className="col-xs-12 inline">
				<Paginacion
					nrPag={List.length}
					NRes={NRes}
					NTot={NTot}
					Ini={ListParams.ini}
					setListParams={setListParams}
					Num={ListParams.num}
					Filtro={ListParams.filtro}
				/>
				<Filtrado
					setListParams={setListParams}
					Filtro={ListParams.filtro}
				/>
			</div>


			<div className="dojos contresto col-xs-12">

				<div className="col-xs-12">
					<table border="0" className="tabladatos tabladojos" style={{ borderCollapse: "collapse" }}>
						<tbody>
							<tr>
								<td style={{ width: '40%' }}>
									<button onClick={() => orden('fecha')}>{loc('Fecha')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'fecha' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'fecha DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '40%' }} className='ocultamovil'>
									<button onClick={() => orden('ciudad')}>{loc('Ciudad')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'ciudad' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'ciudad DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								{mostrarCampos && (
									<td style={{ width: '20%' }}></td>
								)}
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-xs-12 scroll">
					<table border="0" className="tablaresultados tabladojos" style={{ borderCollapse: "collapse" }}>
						<tbody>
						
							{List.map((elem) => (
								<Fragment key={elem.idCurso} >
								<tr className={parseInt(elem.idCurso) === parseInt(idReg) ? 'selected' : ''}>
									<td style={{ width: '40%' }}>
										<div className='fixposition'><img src={arrow} alt='arrow' className='arrow'/>
											<p className="fixsangria">{moment(elem.fecha).format('DD/MM/yyyy')}</p>
										</div>
									</td>
									<td style={{ width: '40%' }} className='ocultamovil'>{elem.ciudad}</td>
									{mostrarCampos && (
										<td style={{ width: '20%' }} className='ocultamovil'>
											{!perfil_admin&& (elem.cfAsoc!==perfil_asoc)? '':
												<ul>
													<li><button><span><img src={write} alt='write' onClick={() => setVerMod(elem.idCurso)} /></span></button></li>
													<li><button><span><img src={trash} alt='trash' onClick={() =>
															Swal.fire({
																title: "¿Seguro que quieres eliminar el dojo?",
																showCancelButton: true,
																confirmButtonText: "Sí",
																icon:'question',
																cancelButtonText: 'No'
															}).then((result) => {
																if (result.isConfirmed) borrar(elem.idDojo)
															})} /></span></button>
													</li>
												</ul>
											}
										</td>
									)}
								</tr>
								{Detalles===elem.idDojo &&
								<>
									<tr className={`borde_gris`}></tr>
									<tr className={`listaoculta`}>
										<td colSpan={6}>
											<ul>
												<li>{elem.ciudad}</li>
												<li>
													<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }}  className='d-flex justify-content-end'>
														<button className="btred" style={{ width: '100%' }}>
															<span>
																{loc('Ver asociados')}
															</span>
															<span>{elem.numAsoc>0?`(${elem.numAsoc})`:''}</span>
														</button>
													</Link>
												</li>
												<li>
												{!perfil_admin&& (elem.cfAsoc!==perfil_asoc)? '':
													<ul>
														<li><button><span><img src={write} alt='write' onClick={() => setVerMod(elem.idDojo)} /></span></button></li>
														<li><button><span><img src={trash} alt='trash' onClick={() =>
																Swal.fire({
																	title: "¿Seguro que quieres eliminar el dojo?",
																	showCancelButton: true,
																	confirmButtonText: "Sí",
																	icon:'question',
																	cancelButtonText: 'No'
																}).then((result) => {
																	if (result.isConfirmed) borrar(elem.idDojo)
																})} /></span></button>
														</li>
													</ul>
												}
												</li>
											</ul>
										</td>
									</tr>
								</>
								}
								</Fragment>

							))

							}
						</tbody>
					</table>
				</div>
			</div>
			{(perfil_admin|perfil_adm_asoc|perfil_adm_dojo) ?
			<div> 
				<button 
					className="btred" 
					onClick={() => setVerMod(-1)}>
						{loc('Añadir nuevo curso')}
					<span><img src={mas} alt='mas' /></span>
				</button>
			</div>
			:''
			}
			<CursosMod
				show={VerMod}
				ListParams={ListParams}
				setListParams={setListParams}
				onHide={() => setVerMod(0)}
			/>

		</>
	)
}

export default Cursos