import React, {useContext,useEffect,useCallback,useState} from "react";
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useParams
} from "react-router-dom";

//Contexto
import ConexContext from "./context/conex/ConexContext";


//componentes
import Login from "./components/Login";
import Dojos from "./components/Dojos";
import Layout from "./components/Layout";
import Asocs from "./components/Asocs";
import Asociados from "./components/Asociados";
import { Inicio } from "./components/Inicio";
import Cuotas from "./components/Cuotas";
import Validaciones from "./components/Validaciones";
import Cursos from "./components/Cursos";

function App() {
	const {token,
		setToken,
		perfil_adm_dojo,
		perfil_admin,
		perfil_adm_asoc,
		peticion
	}=useContext(ConexContext)
	const { lang } = useParams();
	//STATES
	const MaxTimer=1860// tiempo que debe saltar (sg) Si no es más que el tiempo de caducidad del token, no expirará nunca
	const [WCuotas, setWCuotas] = useState(0) //alertas de cuotas
	const [Timer, setTimer] = useState(-1) // contador de tiempo
	
	const guaLocalToken = useCallback(()=>{
		if ('token' in localStorage) // hay un token guardado
			if (localStorage.token)// no esta vacío
				setToken(localStorage.token)
	},[setToken])
	//EFFECT
	useEffect(() => {
		if (!token)//no hay token en el state, miramos en el almacenamiento local
			guaLocalToken()
		//console.log('token',token);	
	}, [token,guaLocalToken])

	// effect para ver los warnings
	useEffect(() => {
		const warning = async () => {
			const pet = await peticion('/cuotas/warnings')
			if (pet?.estado??false)//algún error 
				setTimer(-1)
			setWCuotas(pet?.res??0)
		}
		if (Timer===0){
			if (perfil_adm_asoc | perfil_admin)
				warning()
			else 
				setWCuotas(0)
		}
	},[peticion,perfil_adm_asoc,perfil_admin,Timer]);

	//contador tiempo atrás
	useEffect(() => {
		if (Timer>=0){
			const tp=setTimeout(() => {
				let t=Timer
				if (t===0) t=MaxTimer
				setTimer(t-1);
			}, 1000);
			return () => clearTimeout(tp);
		}
	}, [Timer]);

	const mostrarCuotas = perfil_adm_dojo || perfil_admin || perfil_adm_asoc === true

	if (!token) {
		return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/es" /> } exact />
				<Route path="/:lang/*" element={<Login />}/>
			</Routes>
		</BrowserRouter>
		)

	}else{
		return(
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navigate to="/es" /> } exact />
					<Route path="/:lang" element={<Layout WCuotas={WCuotas} setTimer={setTimer}/>}>
						<Route index element={<Inicio />} />
						<Route path="/:lang/dojos/:asoc" element={<Dojos />}>
							<Route path=":idReg" element={<Dojos/>} />
						</Route>
						<Route path="/:lang/asociaciones" element={<Asocs/>}>
							<Route path=":idReg" element={<Asocs/>} />
						</Route>
						<Route path="/:lang/asociados/"  element={<Navigate to={`/${lang}/asociados/dojo_`}/>} />
						<Route path="/:lang/asociados/:dojo" element={<Asociados />}>
							<Route path=":idReg" element={<Asociados />}>
								<Route path=":acc" element={<Asociados />} />
							</Route>
						</Route>
						{mostrarCuotas && (
						<Route path="/:lang/cuotas" element={<Cuotas setTimer={setTimer}/>}>
							<Route path=":idReg" element={<Cuotas setTimer={setTimer}/>} />
						</Route>
						)}
						<Route path="/:lang/validaciones" element={<Validaciones />}>
							<Route path=":idReg" element={<Validaciones />} />
						</Route>
						<Route path="/:lang/cursos" element={<Cursos/>}>
							<Route path=":idReg" element={<Cursos/>} />
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
		)
	}
}
export default App;
