import React, {useState,useRef,useContext,useEffect,Fragment} from 'react';
import {useNavigate,useParams} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';


import '../assets/css/modal.css';

import moment from 'moment';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imágenes
import close2 from "../assets/img/close2.svg"
import locationmas from "../assets/img/curso.svg"
import save from "../assets/img/save.svg"
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import arrow from "../assets/img/arrow.svg"
import nopin from "../assets/img/pin-01.svg";
import pin from "../assets/img/pin-02.svg";


const avatars =require.context('../../public/avatars/',false,/\.jpg$/)

const CursosMod = (props) => {
	const {peticion,loc}=useContext(ConexContext)
	const navigate = useNavigate();
	const {lang} = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guarda,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})
	useHotkeys('esc', cierra,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})

	//STATES
	const [Anio,setAnio]=useState('')// selec de años
	const [NumAd,setNumAd]=useState(0)// cuotas de adultos
	const [NumNi,setNumNi]=useState(0)// cuotas de niños
	const [ListParams,setListParams]=useState({
		orden:'numAcae',
		selec:[],
		recarga:false
	})
	const [List,setList]=useState([])// listo alumnos
	const [Cargando, setCargando] = useState(false) //Para el cargado 
	const [Lectura, setLectura] = useState(false) //está en modo lectura

	const [BProf, setBProf] = useState(false) //input de busqueda de transf

	const [Formu, setFormu] = useState({}) //todos los campos del formulario
	
	const [ErrList,setErrList]=useState('')// Error en el listado

	//REFs
	const idTimer = useRef(null);
	const rFecha = useRef();

	//devuelve el img del avatar, la por defecto si no existe 
	const imgAvatar = (id) => {
		return(avatars.keys().includes(`./id_${id}.jpg`)?avatars(`./id_${id}.jpg`):avatars(`./avatar.jpg`))
	}
	//quita o pone un elemento del Select 
	const toggleSelec = ( val) =>{
		if (Lectura) return
		const a=[...ListParams.selec]
		let p=[...Formu.aProfs]
		var index = a.indexOf(val);
		if (index === -1){
			a.push(val);
			p.push(List.filter((el)=>el.idAso===val)[0]);

		} 
		else{
			a.splice(index, 1);
			// p.slice(1,1)
			p.splice(p.indexOf(p.filter((el)=>el.idAso===val)[0]),1)

		}
		let profs=p.map(el => {return(`${el.nombre} ${el.apellido}`)}).join(', ')
		console.log('profs',profs);
		setListParams((prev)=>({...prev,selec:a}))
		setFormu((prev)=>({...prev,aProfs:p,profs:profs}))
	}
	//EFFECT
	//busqueda de profesor
	useEffect(() => {
		async function list(){
			setCargando(true)
			const pet=await peticion(`/cursos/listAsoc?filtro=${BProf}&orden=${ListParams.orden}&prof`)
			if (pet?.estado??true)//algún error 
				return;
			setCargando(false)
			setList(pet?.res??[])
			setListParams((prev)=>({
				...prev,
				recarga:false
			}))
		}
		if (ListParams.recarga)
			list()
	}, [ListParams]);
	// effect de carga de datos inicial
	useEffect(() => {
		if (BProf.length>2){// si es de 3 o más, y pasan unos segundos, llamamos al listado
			clearTimeout(idTimer.current)
			idTimer.current=setTimeout(()=>{
				setListParams((prev)=>({
					...prev,
					recarga:true
				}))
		},1000)
		}
		else{
			clearTimeout(idTimer.current)
			setList([])
		}
	}, [BProf]);
	// effect de carga de datos inicial
	useEffect(() => {
		async function ver(){
			setCargando(true)
			const pet=await peticion('/cuotas/ver?id='+ props.show)
			if (pet.estado)//algún error 
				return;
			setCargando(false)
			setListParams((prev)=>({...prev,
				selec:pet.res.selec,
				anio:pet.res.anio,
				recarga:true
			}))
			rFecha.current.focus()
			if (pet.res.cfValDojo!==0) setLectura(true)
		}
		// console.log('props.show',props.show);
		if (props.show>0) 
			ver()
		else if (props.show<0){
			setFormu({
				fecha:'',
				ciudad:'',
				obs:'',
				aSesion:[],
			})
			setAnio('')
			setNumAd(0)
			setNumNi(0)
			setListParams((prev)=>({...prev,
				anio:'',
			}))
			 rFecha.current.focus()
		}
	}, [props.show,peticion])

	// guardar el registro
	async function guarda(){
		// requeridos
		const error={}
		// if (Formu.fecha==='') error.fechaErr=loc('La fecha es requerida.')
		// if (Formu.ciudad==='') error.ciudadErr=loc('La ciudad es requerida.')
		// if (Formu.obs==='') error.obsErr=loc('Debes poner los detalles del curso .')
		// if (Formu.fecha!=='' && !moment(Formu.fecha).isAfter(moment())) error.fechaErr=loc('La fecha debe ser futura.')
		// if (Formu.aSesion.length<1) error.nSesionesErr=loc('Debe haber al menos una sesion')
		// let err=''
		// Formu.aSesion.forEach(el=>{
		// 	if (el.fecha===''|el.hIni===''|el.hFin==='')
		// 		err=loc('La fecha, hora de inicio y hora de fin de la sesión es obligatoria')
		// })
		// if (err!=='') error.sesionesErr=err

// filtros sin necesidad de peticiones
		if (Object.keys(error).length>0){//algún error
			Object.assign(Formu,error)
			setFormu({...Formu})
			return;
		}
		let pet
		if (props.show<0)//inserción
			pet=await peticion('/cursos/ins',{method:'POST',json:Formu})
		else if (props.show>0)//modificación
			pet=await peticion('/cuotas/mod',{method:'POST',json:{
				id:props.show,
				anio:Anio,
				selec:ListParams.selec,
			}})
		let id=pet?.res?.id??props.show
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev)=>({...prev}))
		else{
			navigate(`/${lang}/cursos/${id}`)
		}
		
	}
	//cierra el modal
	function cierra(){
		setFormu({...Formu,fechaErr:'',ciudadErr:''})
		props.onHide()
	}
	//incrementa o decrmenta 
	function incrSesion(incr){
		if (incr>0){
			Formu.aSesion.push({
				fecha:Formu.fecha,
				hIni:'',
				hFin:'',
			})
		}
		else Formu.aSesion.pop()
		setFormu({...Formu,aSesion:Formu.aSesion,sesionesErr:'',nSesionesErr:''})
	}
	//orden subformulario
	function orden(campo){
		if (ListParams.orden === campo)
			campo=campo + ' DESC'
		setListParams({
			...ListParams,
			orden:campo,
			recarga:true
		})
	}
	
	if (props.show===0)
		return("")
	else
		return (
<main className="custom-modal modalasocs modalcuotas">
	<div className="modal-main" >
		<div className="container_modal col-xs-8 d-flex">
			<div className="nuevazona col-xs-12">
				<div className="col-xs-12 d-flex">
					<h4 className="col-xs-12">
						<img src={locationmas} alt='cerrar' title='cerrar'/> {props.show<0?loc('Añadir nuevo curso'):loc('Modificar curso')}
					</h4>
					<button type="button" onClick={cierra} className="btn cerrarmodal" alt="cerrar"><span><img src={close2} alt='cerrar' title='cerrar'/></span></button>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Fecha')}*</p>
					<input type="date"
						value={Formu?.fecha??''}
						ref={rFecha}
						onChange={(self) => {setFormu({...Formu,fecha:self.target.value,fechaErr:''})}}
						placeholder={loc('Fecha de celebración del curso')} />
					<p className='error'>{Formu.fechaErr}</p>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Ciudad')}*</p>
					<input 
						value={Formu?.ciudad??''}
						onChange={(self) => {setFormu({...Formu,ciudad:self.target.value,ciudadErr:''})}}
						placeholder={loc('Ciudad')} />
					<p className='error'>{Formu.ciudadErr}</p>
				</div>
				<div className="col-xs-4 continput">
					<p>{loc('Número de sesiones')}*</p>
					<input 
						disabled={true}
						value={(Formu?.aSesion??[]).length} style={{textAlign:'right'}}
						placeholder={loc('Sesiones')} />
					<button className='boton_input' onClick={()=>incrSesion(1)}  style={{left:'10px'}}><img src={despliega} alt="mas"/></button>
					<button className='boton_input' onClick={()=>incrSesion(-1)} style={{left:'35px'}}><img src={pliega} alt="menos" /></button>
					<p className='error'>{Formu.nSesionesErr}</p>
				</div>

				<div className="col-xs-12 continput divtabla" style={{marginBottom:'5px'}}>
					<table border="0" className="tablaejem tabladatos" style={{ borderCollapse: "collapse" }}>
						<thead>
							<tr style={{ width: '100%' }}>
								<th style={{ width: '33%'}}>
									<button disabled >{loc('Fecha')}</button>
								</th>
								<th style={{ width: '33%'}}>
									<button disabled >{loc('Hora inicio')}</button>
								</th>
								<th style={{ width: '33%'}}>
									<button disabled >{loc('Hora fin')}</button>
								</th>
							</tr>
						</thead>
					</table>
				</div>
				{(Formu?.aSesion??[]).map((el,i)=>(
					<Fragment key={i}>
						<div className="col-xs-4 continput" style={{marginBottom:'5px'}}>
							<input type="date"
								value={el.fecha}
								onChange={(self) => {Formu.aSesion[i].fecha=self.target.value;setFormu({...Formu,aSesion:Formu.aSesion,sesionesErr:''})}}
							/>
						</div>
						<div className="col-xs-4 continput" style={{marginBottom:'5px'}}>
							<input type='time'
								value={el.hIni}
								onChange={(self) => {Formu.aSesion[i].hIni=self.target.value;setFormu({...Formu,aSesion:Formu.aSesion,sesionesErr:''})}}
							/>
						</div>
						<div className="col-xs-4 continput" style={{marginBottom:'5px'}}>
							<input type='time'
								value={el.hFin}
								onChange={(self) => {Formu.aSesion[i].hFin=self.target.value;setFormu({...Formu,aSesion:Formu.aSesion,sesionesErr:''})}}
							/>
						</div>
					</Fragment>
				))}
				<p className='error continput'>{Formu.sesionesErr}</p>
				<div className="col-xs-12 continput" style={{maxHeight:'unset'}}>
					<p>{loc('Detalles')}</p>
					<textarea
						rows="5"
						style={{width:'100%'}}
						value={Formu.obs}
						onChange={(self) => {setFormu({...Formu,obs:self.target.value,obsErr:''})}}
						placeholder={loc('Detalles del curso. Profesores, recomendaciones, posibles alojamientos...')} />
					<p className='error'>{Formu.obsErr}</p>
				</div>
				{/* <div className="col-xs-12 continput" >
					<p>{loc('Profesores')}</p>
					<input
						value={Formu.profs}
						disabled={true}
						placeholder={loc('Busca los profesores y seleccionalos')} />
					<p className='error'> </p>
				</div>
				<div className="col-xs-12 continput" >
					<p>{loc('Buscar profesor')}</p>
					<input
						onChange={(self) => {setBProf(self.target.value)}}
						placeholder={loc('Nombre profesor')} />
					<p className='error'> </p>
				</div>
			{Cargando?(
						<div className="col-xs-12">
							<div className="precarga"><img src={loading} alt="cargando"/></div>
						</div>
					):(

				<div className="contresto col-xs-12" >

				<div className="col-xs-12 ">

					<div className='col-xs-12 divtabla'>
						<table border="0" className="tablaejem tabladatos" style={{ borderCollapse: "collapse" }}>
							<thead>
								<tr key="cabeceradatostabla" style={{ width: '100%' }}>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('numAcae')}>{loc('Núm. ACAE')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'numAcae' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'numAcae DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'nombre' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'nombre DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '40%' }}>
										<button onClick={() => orden('apellido')}>{loc('Apellidos')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'apellido' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'apellido DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '10%',textAlign:'right' }}>
										<button disabled onClick={() => orden('numAcae')}>{loc('Selecciona')}</button>
									</th>
								</tr>
							</thead>
						</table>
					</div>
				</div>
				<div className="col-xs-12 scroll">
					<table border="0" className="tablaresultados" style={{ borderCollapse: "collapse" }}>
						<tbody>
							{List.map((elem) => ((Lectura & !ListParams.selec.includes(elem.idAso))?null :
								<tr key={elem.idAso} className={'alumn'+(elem.selec?'':(ListParams.selec.includes(elem.idAso)?'Act':'Des'))} onClick={()=>{if (!elem.selec) toggleSelec(elem.idAso)}}>
									<td style={{ width: '20%' }}>
										<img src={arrow} alt='arrow' className='arrow'/>
										<div className="photo_row">
											<img src={imgAvatar(elem.idAso)} alt="fotoperfil" />
										</div>
										<span style={{paddingLeft:'25px'}}>{elem.numAcae}</span>
									</td>
									<td style={{ width: '20%' }}>{elem.nombre}</td>
									<td style={{ width: '40%' }}>{elem.apellido}</td>
									<td style={{ width: '10%' }}>
										<div  className="contpin">
											{elem.selec? loc('Ya remesado') : ListParams.selec.includes(elem.idAso)?
												<img src={pin} alt="" className='imgpin'/>
												:
												<img src={nopin} alt="" className='imgpin'/>
											}
										</div>
									</td>
								</tr>
							))}
							
						</tbody>
					</table>
					

				</div>
				<div className="col-xs-12 ">
					<p className='error'>{ErrList}</p>
				</div> 
				</div> 
					)} */}

				{!Lectura &&
				<div className="col-xs-12 contbutton">
					<button type="button" onClick={guarda} className="btn btnguardar ">{loc('Guardar')} <span><img src={save} alt='guardar' title='guardar'/></span></button>
					<p className='error'></p>
				</div>
				}
			</div>
		</div>
	</div>
</main>

		);
}
 
export default CursosMod;