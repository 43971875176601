import React, { useState, useContext, useEffect, Fragment} from 'react'
import {useNavigate, useParams, Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imagenes
import arrow from "../assets/img/arrow.svg"
import write from "../assets/img/write.svg"
import view from "../assets/img/view.svg"
import trash from "../assets/img/trash.svg"
import logo from "../assets/img/cuote.svg"
import mas from "../assets/img/mas.svg"
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import valida1 from "../assets/img/dojoVal.svg";
import valida2 from "../assets/img/usersVal.svg";
import valida3 from "../assets/img/adminVal.svg";
import novalida1 from "../assets/img/dojo.svg";
import novalida2 from "../assets/img/users.svg";
import novalida3 from "../assets/img/admin.svg";
import validar from "../assets/img/validar.svg";
import novalidar from "../assets/img/novalidar.svg";

import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";

import nopin from "../assets/img/pin-01.svg";
import pin from "../assets/img/pin-02.svg";

//modulos
import CuotasMod from './CuotasMod'
import Filtrado from './comun/Filtrado'
import Paginacion from './comun/Paginacion';
import Avatar from './comun/Avatar';

const Remesas = ({setTimer}) => {
	const { 
		peticion,
		loc, 
		perfil_admin,
		perfil_adm_dojo,
		perfil_adm_asoc,
		perfil_dojo,
		perfil_asoc
	} = useContext(ConexContext)//contexto
	const { idReg, lang } = useParams();
	const navigate = useNavigate();

	//STATES
	const [VerMod, setVerMod] = useState(0)// para ver el modal de Ficheros
	const [List, setList] = useState([])// listado
	const [NRes, setNRes] = useState(0)// Nº de registros con filtro
	const [NTot, setNTot] = useState(0)// Nº de registros con filtro
	const [ListParams,setListParams]=useState({
		num:15,
		orden:'anio DESC',
		filtro:'',
		ini:0,
		idReg:0
	})
	const [Cargando, setCargando] = useState(true) //Para el cargado 
	const [Selec, setSelec] = useState([]) //checks seleccionados 
	
	//atajos de teclado
	useHotkeys('alt+n', () => {if (perfil_adm_dojo ) setVerMod(-1)})
	// FUNCIONES
	//se va a poder seleccionar?
	const esClicable = (el) =>{
		let val=false
		if (perfil_adm_dojo&(el.valNiv===1 |el.desValNiv===1)) val=true
		if (perfil_adm_asoc&(el.valNiv===2 |el.desValNiv===2)) val=true
		if (perfil_admin&(el.valNiv===3 |el.desValNiv===3)) val=true
		return val
	}
	const esValidable = () =>{
		if (Selec.length===0) return false
		const el0=List.filter((el)=>el.idRem===Selec[0])[0]
		//son todos los valore iguales
		const numIg=List.reduce((a,b)=>{if (a && Selec.includes(b.idRem)) return (el0.valNiv===b.valNiv); return(a) },true)
		if (!numIg) return false
		if (perfil_adm_dojo&el0.valNiv===1) return true
		if (perfil_adm_asoc&el0.valNiv===2) return true
		if (perfil_admin&el0.valNiv===3) return true
		return false
	}

	const esDesValidable = () =>{
		if (Selec.length===0) return false
		const el0=List.filter((el)=>el.idRem===Selec[0])[0]
		//son todos los valore iguales
		const numIg=List.reduce((a,b)=>{if (a && Selec.includes(b.idRem)) return (el0.desValNiv===b.desValNiv); return(a) },true)
		if (!numIg) return false
		if (perfil_adm_dojo&el0.desValNiv===1) return true
		if (perfil_adm_asoc&el0.desValNiv===2) return true
		if (perfil_admin&el0.desValNiv===3) return true
		return false
	}

	//quita o pone un elemento del Select 
	const toggleSelec = (elem) =>{
		const a=[...Selec]
		var index = a.indexOf(elem.idRem);
		if (index === -1) {
			if (!esClicable(elem)) return
			if (Selec.length>0){
				const el0=List.filter((el)=>el.idRem===Selec[0])[0]
				if (el0.valNiv!==elem.valNiv && (el0.desValNiv!==elem.desValNiv | el0.desValNiv===0)){
					Swal.fire({
							title: loc("No puedes seleccionar remesas validadas y no validadas."),
							showConfirmButton: false,
							icon:'warning',
							timer:1500
						})
					return;
				}
				if (el0.anio!==elem.anio){
					Swal.fire({
						title: loc("No puedes validar dos años diferentes."),
						showConfirmButton: false,
						icon:'warning',
						timer:1500
					})
					return;
				}
			}
			a.push(elem.idRem);
		}
		else
			a.splice(index, 1);
		setSelec(a)
	}

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/cuotas/del', {
			method: 'POST', json: {
				id: id
			}
		})
		if (pet?.estado??0===9){
			Swal.fire({
				title: loc("La remesa no se puede eliminar."),
				text: loc("La remesa ya está validada."),
				showConfirmButton: true,
				icon:'error',
			})
			return
		}
		if (pet?.estado??true)//algún error 
			return;
		Swal.fire({
			title: loc("Remesa de cuotas eliminada."),
			showConfirmButton: false,
			icon:'success',
			timer:1500
		})
		setListParams({...ListParams})
	}
	// valida cuotas
	const valida = async (validar) => {
		const el0=List.filter((el)=>el.idRem===Selec[0])[0]
		const pet = await peticion('/cuotas/valida', {
			method: 'POST', json: {
				selec: Selec,
				valida:validar,
				nivel:validar?el0.valNiv:el0.desValNiv
			}
		})
		if (pet?.estado??0===11){
			Swal.fire({
				title: loc("La remesa no se ha podido validar."),
				showConfirmButton: true,
				icon:'error',
			})
			return
		}
		if (pet?.estado??true)//algún error 
			return;
		Swal.fire({
			title: validar?loc("Remesa de cuotas validada."):loc("Remesa de cuotas desvalidada."),
			showConfirmButton: false,
			icon:'success',
			timer:1500
		})
		setSelec([])
		setTimer(0)
		setListParams({...ListParams})
	}

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({...ListParams,orden:campo + ' DESC'})
		else
			setListParams({...ListParams,orden:campo})
	}

	//EFFECT
	useEffect(() => {
		//listado en sí de los asociados
		const listar = async () => {
	
			const pet = await peticion('/cuotas/?'+
				`ini=${ListParams.ini}&`+
				`pag=${ListParams.num}&`+
				`orden=${ListParams.orden}&`+
				`filtro=${ListParams.filtro}&`
			)
			if (pet?.estado??false)//algún error 
				return null;
			// vamos a enriquecer el listado, para saber si es validable o desvalidable
			pet.res.forEach(el => {
				el.valNiv=0
				el.desValNiv=0
				if (perfil_adm_dojo & el.cfDojo===perfil_dojo) {
					if (el.cfAsocValDojo===0 ) el.valNiv=1
					else if (el.cfAsocValAsoc===0) el.desValNiv=1
				}
				if (perfil_adm_asoc & el.cfAsoc===perfil_asoc & el.cfAsocValDojo!==0) {
					if (el.cfAsocValAsoc===0 ) el.valNiv=2
					else if (el.cfAsocValAdmi===0) el.desValNiv=2
				}
				if (perfil_admin & el.cfAsocValAsoc!==0) {
					if (el.cfAsocValAdmi===0 ) el.valNiv=3
					else el.desValNiv=3
				}
			});
			setList(pet.res)
			setNRes(pet.nRes)
			setNTot(pet.nTot)
			setCargando(false)
		}
		//devuelve la pos de un id
		const getPosicion=async(id)=>{
			const pet = await peticion('/cuotas/posicion?'+
			`id=${id}&`+
			`orden=${ListParams.orden}&`+
			`filtro=${ListParams.filtro}`)
			if (pet?.estado??false)//algún error 
				return ;
			if (pet?.res?.pos??false){
				const pos=pet.res.pos-(pet.res.pos-1)%ListParams.num-1
				if (pos!==ListParams.ini)
					setListParams((prev)=>({...prev,
						ini:pet.res.pos-(pet.res.pos-1)%ListParams.num-1,
						idReg:id
					}))
				else setListParams((prev)=>({...prev, idReg:id}))
			}
			else navigate(`/${lang}/cuotas/`)
		}
		const idRegN=(isNaN(parseInt(idReg))?0:parseInt(idReg))//id de la remesa de la url en número
		// console.log('idRegN',idRegN,ListParams.idReg);
		if (ListParams.idReg!==idRegN)// hay que listar un asociado concreto
			getPosicion(idRegN)
		else{
			setCargando(true)
			listar()
		}
	}, [ListParams,peticion,idReg,lang,navigate,perfil_adm_asoc,perfil_adm_dojo,perfil_admin,perfil_asoc,perfil_dojo])
	return (
		<>
			<div className="col-xs-12">
				<div className="col-xs-9">
					<nav aria-label="breadcrumb" className='miga col-xs-12'>
						<ul className="breadcrumb col-xs-12">
							<li className="breadcrumb-item">
								<Link to={'/'}>
									{loc('Inicio')}
								</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">{loc('Cuotas')}</li>
						</ul>
					</nav>
					<div className="col-xs-12 titularh2">
						<img src={logo} alt='asoc' />
						<h2>{loc('Remesa de cuotas')}</h2>
					</div>
				</div>
				<Avatar/>
			</div>

			<div className="col-xs-12 inline">
			<Paginacion
					nrPag={List.length}
					NRes={NRes}
					NTot={NTot}
					Ini={ListParams.ini}
					setListParams={setListParams}
					Num={ListParams.num}
					Filtro={ListParams.filtro}
				/>
				<Filtrado
					setListParams={setListParams}
					Filtro={ListParams.filtro}
				/>
			</div>


			<div className="contresto cuotas col-xs-12">

				<div className="col-xs-12">
					<table border="0" className="tabladatos tablacuotas" style={{ borderCollapse: "collapse" }}>
						<tbody>
							<tr>
								<td style={{ width: '25%' }}>
									<button onClick={() => orden('dojo')}>{loc('Dojo')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'dojo' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'dojo DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '25%' }} className='ocultamovil'>
									<button onClick={() => orden('asociacion')}>{loc('Asociación')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'asociacion' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'asociacion DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '10%' }} className='ocultamovil'>
									<button onClick={() => orden('anio')}>{loc('Año')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'anio' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'anio DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '8%' }} className='ocultamovil'>
									<button onClick={() => orden('numAd')}>{loc('Nº Adultos')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'numAd' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'numAd DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '8%' }} className='ocultamovil'>
									<button onClick={() => orden('numNi')}>{loc('Nº Niños')}</button>
									<ul className="listaordena">
										{ListParams.orden === 'numNi' &&
											<li><img src={asc} alt='ordena ascendente' /></li>
										}
										{ListParams.orden === 'numNi DESC' &&
											<li><img src={desc} alt='ordena descendente' /></li>
										}
									</ul>
								</td>
								<td style={{ width: '14%' }} >
									<p>{loc('Validaciones')}</p>
								</td>
								<td style={{ width: '5%' }} ></td>
								<td style={{ width: '5%' }} ></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-xs-12 cuotas scroll">
					<table border="0" className="tablaresultados tablacuotas" style={{ borderCollapse: "collapse" }}>
						<tbody>
							{List.map((elem) => (
								<Fragment key={elem.idRem}>
								<tr  
									className={(esClicable(elem)?'clikable ':'')+(Selec.includes(elem.idRem) ? 'selected' : '')}
									onClick={()=>toggleSelec(elem)}
									>
									<td style={{ width: '25%' }}><img src={arrow} alt='arrow' className='arrow'/>
										{Selec.includes(elem.idRem)?
											<img src={pin} alt="" className={'imgpin'+(!esClicable(elem)?' invisible':'')}/>
											:
											<img src={nopin} alt="" className={'imgpin'+(!esClicable(elem)?' invisible':'')}/>
										}
										{elem.dojo}
									</td>
									<td style={{ width: '25%' }} className='ocultamovil'>{elem.asociacion}</td>
									<td style={{ width: '10%' }} className='ocultamovil'>{elem.anio}</td>
									<td style={{ width: '8%' }} className='ocultamovil' >{elem.numAd}</td>
									<td style={{ width: '8%' }} className='ocultamovil'>{elem.numNi}</td>
									<td style={{ width: '14%' }} >
										{elem.cfAsocValDojo===0?
											<img src={novalida1} alt='validar' title="Pte validar por el dojo" />
											:
											<img src={valida1} alt='validar' title="Validado por el dojo" />
										}
										{elem.cfAsocValAsoc===0?
											<img src={novalida2} alt='validar' title="Pte validar por la asociación" />
											:
											<img src={valida2} alt='validar' title="Validado por la asociación" />
										}
										{elem.cfAsocValAdmi===0?
											<img src={novalida3} alt='validar' title="Pte validar por la secretaría del ACAE" />
											:
											<img src={valida3} alt='validar' title="Validado por la secretaría del ACAE" />
										}
									</td>
									<td style={{ width: '5%' }} className='texto_rojo'>
										{(
											perfil_adm_dojo && elem.cfAsocValDojo===0|
											perfil_adm_asoc && elem.cfAsocValAsoc===0|
											perfil_admin && elem.cfAsocValAdmi===0
											) ?
											loc("Pte."):''

										}
									</td>
									<td style={{ width: '5%' }} >
										<ul>
											{elem.cfAsocValDojo!==0?
											<li><button><span><img src={view} alt='view' onClick={(event) =>{ setVerMod(elem.idRem);event.stopPropagation()}} /></span></button></li>
											:
											<>
											<li><button><span><img src={write} alt='write' onClick={(event) =>{ setVerMod(elem.idRem);event.stopPropagation()}} /></span></button></li>
											<li><button><span><img src={trash} alt='trash' onClick={(event) => {
												Swal.fire({
													title: "¿Seguro que quieres eliminar la remesa de cuotas?",
													showCancelButton: true,
													confirmButtonText: "Sí",
													icon:'question',
													cancelButtonText: 'No'
												}).then((result) => {
													if (result.isConfirmed) borrar(elem.idRem)
												});	
													event.stopPropagation()
												}} /></span></button>
											</li>
											</>
											}
											<li>
												<div className='detalles'>
												<button><img src={despliega} alt="despliega"/></button>
												<button><img src={pliega} alt="pliega" /></button>	
												</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr className='borde_gris'></tr>
								<tr className='listaoculta'>
									<td colSpan={6}>
										<ul>
											<li>{elem.asociacion}</li>
											<li>{elem.anio}</li>
											<li>{elem.numAd}</li>
											<li>{elem.numNi}</li>
										</ul>
									</td>
								</tr>			
								</Fragment>
							))

							}
						</tbody>
					</table>
				</div>
			</div>
				<div className='col-xs-12 cuotas contbutton'>
					{perfil_adm_dojo && 
					<button className="btred btn btnguardar" title='ALT-N' onClick={() => setVerMod(-1)}>
						{loc('Añadir nueva remesa de cuotas')} <span><img src={mas} alt='asoc' /></span>
					</button>
					}
					{List.filter(el=>el.valNiv!==0).length>0 &&
						<button className={"btred btn btnguardar"+ (!esValidable()?' Des':'')}  title='ALT-N' onClick={() => {
							Swal.fire({
								title:loc('Vas a validar las remesas seleccionadas. ¿Estás seguro?'),
								text:loc('Asegúrate de que se ha hecho transferencia con el importe del total de todas las cuotas:')+
									` (${List.filter((el)=>Selec.includes(el.idRem)).reduce((tot,el)=>tot+el.numAd,0)} adultos, y ${List.filter((el)=>Selec.includes(el.idRem)).reduce((tot,el)=>tot+el.numNi,0)} niños)`,
								showCancelButton: true,
								confirmButtonText: loc('Sí'),
								icon:'question',
								cancelButtonText: loc('No')
							}).then((result) => {
								if (result.isConfirmed) valida(true)
							});	
						}}>
							{!esValidable()?
								<>{loc('Selecciona una remesa para validar')}<span></span></>
								:
								<>{loc('Validar la remesa')}<span><img src={validar} alt='asoc' /></span></>
							} 
						</button>
					}
					{List.filter(el=>el.desValNiv!==0).length>0 &&
						<button className={"btred btn btnguardar"+ (!esDesValidable()?' Des':'')}  title='ALT-N' onClick={() => {
							Swal.fire({
								title: loc('Vas a desvalidar las remesas seleccionadas. ¿Estás seguro?'),
								showCancelButton: true,
								confirmButtonText: loc('Sí'),
								icon:'question',
								cancelButtonText: loc('No')
							}).then((result) => {
								if (result.isConfirmed) valida(false)
							});	
						}}>
							{!esDesValidable()?
								<>{loc('Selecciona una remesa para quitar validación')}<span></span></>
								:
								<>{loc('Quitar validación de la remesa')}<span><img src={novalidar} alt='asoc' /></span></>
							} 
						</button>
					}
				</div>
			<CuotasMod
				show={VerMod}
				ListParams={ListParams}
				setListParams={setListParams}
				onHide={() => setVerMod(0)}
			/>

		</>
	)
}

export default Remesas