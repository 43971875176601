import React, { useState, useRef, useContext, useEffect } from 'react';
import Avatar from "react-avatar-edit";
import { 
	useNavigate,
	useParams 
} from "react-router-dom";

import moment from 'moment';
import { useHotkeys } from 'react-hotkeys-hook';
import Swal from 'sweetalert2'

import '../assets/css/modal.css';
import validErr from '../assets/js/valida'
//Contexto
import ConexContext from "../context/conex/ConexContext";

//imágenes
import close2 from "../assets/img/close2.svg"
import people from "../assets/img/asociado.svg"
import save from "../assets/img/save.svg"
import link from "../assets/img/link.svg";

const AsociadoMod = (props) => {
	const {
		peticion,
		loc,
		perfil_admin, 
		perfil_adm_asoc, 
		perfil_adm_dojo,
		perfil_dojo,
		perfil_asoc
	 } = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang,acc } = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guardarRegistro,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})
	useHotkeys('esc', cierra,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})

	//REFs
	const rId = useRef()
	const rDNI = useRef()
	const rNombre = useRef()
	const rApellido = useRef()
	const rMail = useRef()
	const rNumACAE = useRef()
	const rFecNacimiento = useRef()
	const rDomicilio = useRef()
	const rCiudad = useRef()
	const rProvincia = useRef()
	const rTelefono = useRef()
	const rObs = useRef()
	const rAsoc = useRef()
	const rDojo = useRef()
	const rBotAdmin = useRef()
	const rBotAdmAs = useRef()
	const rBotAdmDo = useRef()
	

	//STATES
	const [Imagen,setImagen]=useState(null)//imagen del avatar
	const [ClassAvatar,setClassAvatar]=useState('avCerrado')//clase para el crop del avatar
	const [Asociaciones,setAsociaciones]=useState([])//clase con las asociaciones
	const [Dojos,setDojos]=useState([])//clase con los dojos
	const [IdAsociacion,setIdAsociacion]=useState(0)//asociación del select
	const [Mail,setMail]=useState('')//mail
	const [IdDojo,setIdDojo]=useState(0)//dojo del select
	const [EsAdmin,setEsAdmin]=useState(false)//administrador
	const [EsAdmAs,setEsAdmAs]=useState(false)//administrador asociación
	const [EsAdmDo,setEsAdmDo]=useState(false)//administrador dojo
	const [RutAvatar,setRutAvatar]=useState(null)//ruta del avatar
	
	const [ErrDNI, setErrDNI] = useState('') //Error en el DNI
	const [ErrNombre, setErrNombre] = useState('')//Error en el nombre
	const [ErrApellido, setErrApellido] = useState('') //Error en el apellido
	const [ErrMail, setErrMail] = useState('') //Error en el correo
	const [ErrNACAE, setErrNACAE] = useState('') //Error en numAcae
	const [ErrFecha, setErrFecha] = useState('') //Error en la fecha
	const [ErrDomicilio, setErrDomicilio] = useState('') //Error en el domicilio
	const [ErrCiudad, setErrCiudad] = useState('') //Error en la ciudad
	const [ErrProvincia, setErrProvincia] = useState('') //Error en la provincia
	const [ErrTelefono, setErrTelefono] = useState('') //Error en el telefono
	const [ErrAsociacion, setErrAsociacion] = useState('') //Error en el asociación
	const [ErrObs, setErrObs] = useState('') //Error en la observación
	const [ErrDojo, setErrDojo] = useState('') //Error en el Dojo
	const [nombreCompleto, setNombreCompleto] = useState('') // Estado para almacenar el nombre del asociado
	const [Validado, setValidado] = useState('') // Tiene la clave validada?
	const [Clave, setClave] = useState('') // Clave para la validación
	const [Cargando, setCargando] = useState(false) //Para el cargado 


	//EFFECT
	useEffect(() => {//inicial
			async function ver() {
				setCargando(true)
				const pet = await peticion('/asociados/ver?id=' + props.show)
				if (pet.estado) //algún error
						return;
				setCargando(false)
				rId.current.value = pet.res.idAso
				rDNI.current.value = pet.res.dni
				rNombre.current.value = pet.res.nombre
				rApellido.current.value = pet.res.apellido
				rMail.current.value = pet.res.mail
				rNumACAE.current.value = pet.res.numAcae
				rFecNacimiento.current.value = pet.res.fechaNacimiento
				rDomicilio.current.value = pet.res.domicilio
				rCiudad.current.value = pet.res.ciudad
				rProvincia.current.value = pet.res.provincia
				rTelefono.current.value = pet.res.telefono
				rObs.current.value = pet.res.obs
				setNombreCompleto(pet.res.nombreCompleto)
				setIdDojo(pet.res?.cfDojo??0)
				setIdAsociacion(pet.res?.cfAsoc??0)
				setEsAdmin(pet.res.esAdmin)
				setEsAdmDo(pet.res.esAdminDojo)
				setEsAdmAs(pet.res.esAdminAsoc)
				setValidado(pet.res.validado)
				setClave(pet?.res?.clave??'')
				setMail(pet?.res?.mail??'')
				setRutAvatar(pet?.res?.avatar??null)
				rMail.current.focus()
				rMail.current.select()
			}
			async function verAsocs() {//descargo las Asociaciones
				const pet = await peticion('/asocs/?ini=0&pag=1000&orden=nombre&filtro=')
				if (pet.estado) //algún error
					return;
				setAsociaciones(pet?.res??[])
			}	
			if (props.show===0)
				return
			verAsocs()
			if (props.show > 0)
					ver()
			else if (props.show < 0) {//insertando
					rId.current.value = ''
					rDNI.current.value = ''
					rNombre.current.value = ''
					rApellido.current.value = ''
					rMail.current.value = ''
					rNumACAE.current.value = ''
					rFecNacimiento.current.value = ''
					rDomicilio.current.value = ''
					rCiudad.current.value = ''
					rProvincia.current.value = ''
					rTelefono.current.value = ''
					rObs.current.value = ''
					setValidado(false)
					setClave('')
					setMail('')

					let dojo=props.ListParams.idDojo
					let asoc=props.ListParams.cfAsoc
					if (!perfil_admin)
						asoc=perfil_asoc
					if (!perfil_admin & !perfil_adm_asoc)
						dojo=perfil_dojo
					setIdAsociacion(asoc)
					setIdDojo(dojo)
					setEsAdmin(false)
					setEsAdmDo(false)
					setEsAdmAs(false)
					rNumACAE.current.focus()
			}


			// Establecemos el nombreCompleto en blanco para que cuando cargue un asociado no salga el nombre del anterior asociado
			setNombreCompleto('')

	}, [props.show, peticion,perfil_adm_asoc,perfil_admin,perfil_asoc,perfil_dojo,props.ListParams])

	useEffect(() => {
		// console.log('IdAsociacion',IdAsociacion);
		async function verDojos() {//descargo las Asociaciones
			const pet = await peticion('/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc='+IdAsociacion)
			if (pet.estado) //algún error
				return;
			setDojos(pet?.res??[])
		}
		setErrAsociacion('');
		setErrDojo('');
		if (parseInt(IdAsociacion)===0){
			setDojos([])
			return
		}
		verDojos()
	}, [IdAsociacion,peticion]);
// atajos de teclado
	useEffect(() => {
	}, []);


	//guardar registro
	async function guardarRegistro() {
			//filtros sin necesidad de peticiones
			const expMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^$/
			const expDNI = /^([X-Z]\d{7}[A-Z])|(\d{8}[A-Z])$/
			const expNAcae = /^\d{2}[A-Z]\d{4}$|^$/
			// const expTelefono = /^\d{9}$/
			const fechaLimite = moment("1900-01-01"); // fecha límite
			let error=false
			//requeridos
			error|=validErr(rNombre.current.value === '',setErrNombre,loc('El nombre es requerido.'))
			error|=validErr(rApellido.current.value === '',setErrApellido,loc('El apellido es requerido.'))
			// error|=validErr(rMail.current.value === '',setErrMail,loc('El correo es requerido.'))
			error|=validErr(rFecNacimiento.current.value === '',setErrFecha,loc('La fecha de nacimiento es requerida.'))
			error|=validErr(rDojo.current.value === '0',setErrDojo,loc('El dojo es requerido.'))
			error|=validErr(rAsoc.current.value === '0',setErrAsociacion,loc('La asociación es requerida.'))
			error|=validErr(rDNI.current.value !== '' && !expDNI.test(rDNI.current.value),setErrDNI,loc('Introduce un DNI válido.'))
			error|=validErr(!expNAcae.test(rNumACAE.current.value),setErrNACAE,loc('Num. con formato no válido.'))
			error|=validErr(!expMail.test(rMail.current.value),setErrMail,loc('Introduce un correo válido.'))
			error|=validErr(rFecNacimiento.current.value !== '' && 
				(!moment(rFecNacimiento.current.value).isBefore(moment()) || !moment(rFecNacimiento.current.value).isAfter(fechaLimite)),
				setErrFecha,loc('La fecha de nacimiento no es válida'))
			if (props.show<0 && !perfil_admin){//insercion sin ser admin 
				error|=validErr(rAsoc.current.value !== perfil_asoc.toString(),setErrAsociacion,loc('No puedes insertar en esa asociación.'))
				if (!perfil_adm_asoc) //adm_dojo
					error|=validErr(rDojo.current.value !== perfil_dojo.toString(),setErrDojo,loc('No puedes insertar en ese dojo.'))
			}
			if (props.show>0 && !perfil_admin && !perfil_adm_asoc){//modificación sin ser admin o adm_asoc
				error|=validErr(rAsoc.current.value !== perfil_asoc.toString(),setErrAsociacion,loc('No puedes guardar en esa asociación.'))
				if (!perfil_adm_dojo) //sin permisos
					error|=validErr(rDojo.current.value !== perfil_dojo.toString(),setErrDojo,loc('No puedes cambiar de dojo.'))
			}

			if (error)//algún error
				return;
				// segunda tanda de errores
				//filtros con peticiones
				let pet
				// peticion que comprueba que no haya un mail repetido
				if (rMail.current.value!==''){
					pet = await peticion(`/asociados/rep_mail?mail=${rMail.current.value}&id=${props.show}`)
					if (pet?.estado??1) return;
					if ((pet?.res?.idAso ?? 1) > -1) {
						setErrMail(loc('Ya existe un asociado con ese correo'))
						return;
					}
				}
			// peticion que comprueba que no haya un numAcae repetido
			if (rNumACAE.current.value!==''){
				pet = await peticion(`/asociados/rep_numAcae?numAcae=${rNumACAE.current.value}&id=${props.show}`)
				if (pet?.estado??1) return;
				if ((pet?.res?.idAso ?? 1) > -1) {
						setErrNACAE(loc('Ya existe un asociado con ese número'))
						return;
				}
			}
			if (props.show < 0) //insercion
					pet = await peticion('/asociados/ins', {
							method: 'POST',
							json: {
									dni: rDNI.current.value.toUpperCase(),
									nombre: rNombre.current.value,
									apellido: rApellido.current.value,
									mail: rMail.current.value,
									numAcae: rNumACAE.current.value,
									fechaNacimiento: rFecNacimiento.current.value,
									domicilio: rDomicilio.current.value,
									ciudad: rCiudad.current.value,
									provincia: rProvincia.current.value,
									telefono: rTelefono.current.value,
									obs: rObs.current.value,
									esAdmin: EsAdmin,
									esAdmDo: EsAdmDo,
									esAdmAs: EsAdmAs,
									cfDojo: IdDojo,
									cfAsoc: IdAsociacion,
									avatar:Imagen
							}
					})
			else if (props.show > 0) //modificacion
					pet = await peticion('/asociados/mod', {
							method: 'POST',
							json: {
									id: rId.current.value,
									dni: rDNI.current.value.toUpperCase(),
									nombre: rNombre.current.value,
									apellido: rApellido.current.value,
									mail: rMail.current.value,
									numAcae: rNumACAE.current.value,
									fechaNacimiento: rFecNacimiento.current.value,
									domicilio: rDomicilio.current.value,
									ciudad: rCiudad.current.value,
									provincia: rProvincia.current.value,
									telefono: rTelefono.current.value,
									obs: rObs.current.value,
									esAdmin: EsAdmin,
									esAdmDo: EsAdmDo,
									esAdmAs: EsAdmAs,
									cfDojo: IdDojo,
									cfAsoc: IdAsociacion,
									avatar:Imagen
							}
					})

			let id = pet?.res?.id ?? rId.current.value
			if (pet.estado) //algún error
					return
			cierra()
			if (acc==='mod')//quitamos el "mod" de la url
				navigate(`/${lang}/asociados/dojo_${props.ListParams.idDojo}/${id}`)
			else if (parseInt(id) === props.ListParams.idReg)
				props.setListParams((prev)=>({...prev,idReg:0}))
			else 
				navigate(`/${lang}/asociados/dojo_${props.ListParams.idDojo}/${id}`)
			
	}

	//resetea la psw y lo pone pendiente de validar
	async function resetPSW() {
		//filtros sin necesidad de peticiones
		const pet = await peticion('/asociados/resetPSW',{
			method: 'POST',
			json: {
				id: rId.current.value
			}
		})
		if (pet?.estado??1) return;
		let texto=window.location.origin
		texto+=`/${lang}/?mail=${rMail.current.value}&valCode=${pet?.res?.clave??''}`
		navigator.clipboard.writeText(texto)
		Swal.fire({
			title: loc("Contraseña reseteada."),
			text:loc('El usuario tendrá que volver a acceder con el enlace que se ha copiado en el portapapeles.'),			
			showConfirmButton: true,
			icon:'success'
		})
		cierra()
	}

	//copia la url de validaciñon al portapapeles
	async function  copURL() {
		let texto=window.location.origin
		texto+=`/${lang}/?mail=${rMail.current.value}&valCode=${Clave}`
		navigator.clipboard.writeText(texto)
		Swal.fire({
			title: loc("Enlace copiado al portapapeles."),
			text:loc('Mándaselo al asociado, para que pueda validar su usuario.'),			
			showConfirmButton: true,
			icon:'success'
		})
	}
	//cierra el modal
	function cierra() {
			setErrDNI('')
			setErrNombre('')
			setErrApellido('')
			setErrMail('')
			setErrNACAE('')
			setErrFecha('')
			setErrDomicilio('')
			setErrCiudad('')
			setErrProvincia('')
			setErrTelefono('')
			setErrObs('')
			setImagen(null)
			setClassAvatar('avCerrado')
			setEsAdmin(false)
			setEsAdmAs(false)
			setEsAdmDo(false)
			props.onHide()
	}

	if (props.show === 0)
			return ("")
	else
			return (
				<main className="custom-modal modalasocs" >
					<div className="modal-main" >
						<div className="container_modal col-lg-12 col-md-12 ">
							<div className="nuevazona col-xs-12">
								<div className="col-xs-12 d-flex ">
										<h4 className="col-xs-12">
												<img src={people} alt='img'/> {props.show < 0 ? loc('Añadir nuevo asociado') : loc('Modificar asociado')}
										</h4>
										<button type="button" onClick={cierra} className="btn" ><span><img src={close2} alt='cerrar' title='(ESC)' /></span></button>
								</div>

								<div className="col-xs-12 perfilconscroll">

								<div className="col-xs-12 filaperfil">
								<div className="col-xs-6 fotoperfil">
										<div className="photo">
												<img src={'/avatars/'+(RutAvatar??'default.jpg')} alt="fotoperfil" />
										</div>
										<div className="nombreasoc">
											<p>{nombreCompleto}</p>
											
										</div>
										<div className={ClassAvatar}>
											<Avatar
												labelStyle={{position:'absolute',cursor: 'pointer',top: 0,left: 0,height:'50px',width:'50px'}}
												imageHeight={400}
												onFileLoad={()=>setClassAvatar('avAbierto')}
												onClose={()=>{setImagen(null);setClassAvatar('avCerrado')}}
												onCrop={(img)=>setImagen(img)}
												label=""
												exportSize={100}
												lineWidth={0}
												src={null}
											/>
										</div>
								</div>

								<div className="col-xs-6 contbutton">
									<button 
										ref={rBotAdmin}
										type="button" 
										disabled={(!perfil_admin)}
										onClick={()=>{setEsAdmin(!EsAdmin)}} 
										className={`btn btnswitch ${EsAdmin?'Act':'Des'}`}>{loc('Administrador')} 
									</button>
									<button 
										ref={rBotAdmAs}
										type="button" 
										disabled={(!perfil_admin&!perfil_adm_asoc)}
										onClick={()=>{setEsAdmAs(!EsAdmAs)}} 
										className={`btn btnswitch ${EsAdmAs?'Act':'Des'}`}>{loc('Adm. Asociación')} 
									</button>
									<button 
										ref={rBotAdmDo}
										type="button" 
										onClick={()=>{setEsAdmDo(!EsAdmDo)}} 
										disabled={(!perfil_admin&!perfil_adm_asoc&!perfil_adm_dojo)}
										className={`btn btnswitch ${EsAdmDo?'Act':'Des'}`}>{loc('Admin. Dojo')} 
									</button>
								</div>
								</div>



								<div className="col-xs-12 continputs">
								
								
								<div className="col-xs-3 continput">
										<p>{loc('Nº ACAE')}</p>
										<input ref={rNumACAE}
												onChange={() => setErrNACAE('')}
												disabled={props.show > 0}
												placeholder={loc('Num. ACAE. Vacio para autogenerar.')}/>
										<p className='error'>{ErrNACAE}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Mail')}</p>
										<input ref={rMail}
											
											onChange={() => {setMail(rMail.current.value);setErrMail('')}}
											placeholder={loc('Correo del asociado')} />
										<p className='error'>{ErrMail}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Nombre')}*</p>
										<input ref={rNombre}
												onChange={() => setErrNombre('')}
												placeholder={loc('Nombre del asociado')} />
										<p className='error'>{ErrNombre}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Apellidos')}*</p>
										<input ref={rApellido}
												onChange={() => setErrApellido('')}
												placeholder={loc('Apellido del asociado')} />
										<p className='error'>{ErrApellido}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Fecha de nacimiento')}*</p>
										<input type="date"
												className="inputfecha"
												ref={rFecNacimiento}
												onChange={() => setErrFecha('')}
												placeholder={loc('Fecha de nacimiento del asociado')} />
										<p className='error'>{ErrFecha}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Asociación')}*</p>
										<select 
											ref={rAsoc}
											value={IdAsociacion}
											onChange={() => {setIdAsociacion(rAsoc.current.value)}}>
											<option value={0}>{loc('Seleccionar asociación')}</option>
											{Asociaciones.map(
												(elem)=>(
													<option value={elem.idAsoc} key={elem.idAsoc}>{elem.nombre} ({elem.zona})</option>
												))
											}
										</select>
										<p className='error'>{ErrAsociacion}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Dojo')}*</p>
										<select 
												ref={rDojo}
												value={IdDojo}
												onChange={() => {setErrDojo('');setIdDojo(rDojo.current.value)}}>
												<option value={0}>{loc('Seleccionar dojo')}</option>
											{Dojos.map(
												(elem)=>(
													<option value={elem.idDojo} key={elem.idDojo}>{elem.nombre}</option>
												))
											}
										</select>
										<p className='error'>{ErrDojo}</p>
								</div>
								<div className="col-xs-3 continput">
										<input ref={rId} type='hidden' />
										<p>{loc('DNI')}</p>
										<input ref={rDNI}
												onChange={() => setErrDNI('')}
												placeholder={loc('DNI del asociado')} />
										<p className='error'>{ErrDNI}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Domicilio')}</p>
										<input ref={rDomicilio}
												onChange={() => setErrDomicilio('')}
												placeholder={loc('Domicilio del asociado')} />
										<p className='error'>{ErrDomicilio}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Ciudad')}</p>
										<input ref={rCiudad}
												onChange={() => setErrCiudad('')}
												placeholder={loc('Ciudad del asociado')} />
										<p className='error'>{ErrCiudad}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Provincia')}</p>
										<input ref={rProvincia}
												onChange={() => setErrProvincia('')}
												placeholder={loc('Provincia del asociado')} />
										<p className='error'>{ErrProvincia}</p>
								</div>
								<div className="col-xs-3 continput">
										<p>{loc('Telefono')}</p>
										<input ref={rTelefono}
												onChange={() => setErrTelefono('')}
												placeholder={loc('Telefono del asociado')} />
										<p className='error'>{ErrTelefono}</p>
								</div>
								<div className="col-xs-12 continput">
										<p>{loc('Observaciones')}</p>
										<input ref={rObs}
												onChange={() => setErrObs('')}
												placeholder={loc('Observaciones del asociado')} />
										<p className='error'>{ErrObs}</p>
								</div>
								</div>				


								</div>	

								<div className="col-xs-3 continput">
								</div>
								<div className="col-xs-12 contbutton">
									{(props.show<0|Mail==='')?null : !Validado && Clave!=='' ? // solo visible si está por validar y tiene clave (tiene permisos)
										<button 
											type="button" 
											onClick={()=>copURL()} 
											className="btn btnguardar validacion ">
												{loc('Enlace validación')}
												<span><img src={link} alt='guardar' title='guardar' /></span>
										</button>
										:
										<button 
											type="button" 
											onClick={()=>{
												Swal.fire({
													title: loc("¿Seguro que quieres resetar la contraseña de este usuario?"),
													showCancelButton: true,
													confirmButtonText: loc("Sí"),
													icon:'question',
													cancelButtonText: loc('No')
												}).then((result) => {
													if (result.isConfirmed) resetPSW()
												});
											}} 
											className="btn btnguardar ">
												{loc('Resetear contraseña')}
												<span><img src={save} alt='guardar' title='guardar' /></span>
										</button>
									}
									<button 
										title='ALT+G'
										type="button" 
										onClick={guardarRegistro} 
										className="btn btnguardar ">
											{loc('Guardar')} 
											<span><img src={save} alt='guardar' title='guardar' /></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</main>
			)
}

export default AsociadoMod;