import React, {useState,useRef,useContext,useEffect} from 'react';
import {useNavigate,useParams} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';

import '../assets/css/modal.css';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imágenes
import close2 from "../assets/img/close2.svg"
import users from "../assets/img/users.svg"
import save from "../assets/img/save.svg"

const AsocsMod = (props) => {
	const {peticion,loc}=useContext(ConexContext)
	const navigate = useNavigate();
	const {lang} = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guarda,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})
	useHotkeys('esc', cierra,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})

	//STATES
	const [ErrNombre,setErrNombre]=useState('')// Error en el nombre
	const [ErrSerie,setErrSerie]=useState('')// Error en la serie

	//REFs
	const rId = useRef();	
	const rNombre = useRef();	
	const rZona = useRef();
	const rSerie = useRef();

	//EFFECT
	useEffect(() => {
		async function ver(){
			const pet=await peticion('/asocs/ver?id='+ props.show)
			if (pet.estado)//algún error 
				return;
			rId.current.value=pet.res.idAsoc
			rNombre.current.value=pet.res.nombre
			rZona.current.value=pet.res.zona
			rSerie.current.value=pet.res.serie
			rNombre.current.focus()
			rNombre.current.select()
	}
		// console.log('props.show',props.show);
		if (props.show>0) 
			ver()
		else if (props.show<0){
			rId.current.value=''
			rNombre.current.value=''
			rZona.current.value=''
			rSerie.current.value=''
			rNombre.current.focus()
		}
	}, [props.show,peticion])

	// guardar el registro
	async function guarda(){
		//filtros sin necesidad de peticiones
		if (rNombre.current.value==='')
			setErrNombre(loc('El nombre es requerido.'))
		else if(!/^[0-9]{2}[A-Z]$/.test(rSerie.current.value))
			setErrSerie(loc('Formato incorrecto para la serie'))
		else {
			//filtros con peticiones
			let pet
			//comprobamos que nombre no está repetido
			pet=await peticion(`/asocs/rep_nombre?nombre=${rNombre.current.value}&id=${props.show}`)
			if (!pet?.estado??1)//estado OK
				if ((pet?.res?.idAsoc??1)>-1){
					setErrNombre(loc('Ya existe una asociación con ese nombre.'))
					return;
				}	
			pet=await peticion(`/asocs/rep_serie?serie=${rSerie.current.value}&id=${props.show}`)
			if (!pet?.estado??1)//estado OK
				if ((pet?.res?.idAsoc??1)>-1){
					setErrSerie(loc('Ya existe una asociación con esa serie.'))
					return;
				}	
				
			if (props.show<0)//inserción
				pet=await peticion('/asocs/ins',{method:'POST',json:{
					nombre:rNombre.current.value,
					zona:rZona.current.value,
					serie:rSerie.current.value,
				}})
			else if (props.show>0)//modificación
				pet=await peticion('/asocs/mod',{method:'POST',json:{
					id:rId.current.value,
					nombre:rNombre.current.value,
					zona:rZona.current.value,
					serie:rSerie.current.value,
				}})
			let id=pet?.res?.id??rId.current.value
			if (pet.estado)//algún error 
				return;
			cierra()
			if (parseInt(id) === props.ListParams.idReg)
				props.setListParams((prev)=>({...prev}))
			else{
				navigate(`/${lang}/asociaciones/${id}`)
			}
		}
	}
	//cierra el modal
	function cierra(){
		setErrNombre('')
		setErrSerie('')
		props.onHide()
	}
	

	if (props.show===0)
		return("")
	else
		return (
<main className="custom-modal modalasocs">
	<div className="modal-main" >
		<div className="container_modal col-lg-4 col-md-12 d-flex">
			<div className="nuevazona col-xs-12">
				<div className="col-xs-12 d-flex mb-3">
					<h4 className="col-xs-12">
						<img src={users} alt='cerrar' title='cerrar'/> {props.show<0?loc('Añadir nueva asociación'):loc('Modificar asociación')}
					</h4>
					<button type="button" onClick={cierra} className="btn" alt="cerrar"><span><img src={close2} alt='cerrar' title='cerrar'/></span></button>
				</div>
				<div className="col-xs-12 continput">
					<input ref={rId} type='hidden'/>
					<p>{loc('Nombre')}:</p> 
					<input ref={rNombre} 
						onChange={()=>setErrNombre('')}
						placeholder={loc('Nombre de la asociación')}/>
					<p className='error'>{ErrNombre}</p>
				</div>
				<div className="col-xs-12 continput">
					<p>{loc('Zona')}:</p>
					<input ref={rZona} placeholder={loc('Zona de la asociación')}/>
					<p className='error'></p>
				</div>
				<div className="col-xs-12 continput">
					<p>{loc('Nº de serie')}:</p> 
					<input ref={rSerie} 
						onChange={()=>setErrSerie('')}
						placeholder={loc('Nº de serie de los asociados')}/>
					<p className='error'>{ErrSerie}</p>
				</div>
				<div className="col-xs-12 contbutton">
					<button type="button" onClick={guarda} className="btn btnguardar ">{loc('Guardar')} <span><img src={save} alt='guardar' title='guardar'/></span></button>
					<p className='error'></p>
				</div>
			</div>
		</div>
	</div>
</main>

		);
}
 
export default AsocsMod;