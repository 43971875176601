import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate,useParams} from "react-router-dom";

import { useHotkeys } from 'react-hotkeys-hook';

import '../assets/css/modal.css';
import validErr from '../assets/js/valida'

//Contexto
import ConexContext from "../context/conex/ConexContext";

//imágenes
import close2 from "../assets/img/close2.svg"
import logomas from "../assets/img/dojo.svg"
import save from "../assets/img/save.svg"

const DojosMod = (props) => {
	const { 
		peticion,
		perfil_admin,
		perfil_asoc,
		loc } = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang } = useParams();

	//STATES
	const [ErrNombre, setErrNombre] = useState('')// Error en el nombre
	const [ErrCiudad, setErrCiudad] = useState('')// Error en la ciudad
	const [ErrAsoc, setErrAsoc] = useState('')// Error de la asoc
	const [Asociaciones,setAsociaciones]=useState([])//clase con las asociaciones
	const [IdAsociacion,setIdAsociacion]=useState(0)//asociación del select
	const [Cargando, setCargando] = useState(false) //Para el cargado 

	//atajos de teclado
	useHotkeys('alt+g', guarda,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})
	useHotkeys('esc', cierra,{enableOnContentEditable: true,enableOnFormTags:true,enabled:props.show !== 0})

	//REFs
	const rId = useRef();
	const rNombre = useRef();
	const rCiudad = useRef();
	const rAsoc = useRef();

	//EFFECT
	useEffect(() => {
		async function ver() {
			setCargando(true)
			const pet = await peticion('/dojos/ver?id=' + props.show)
			if (pet.estado)//algún error 
				return;
			setCargando(false)
			rId.current.value = pet.res.idDojo
			rNombre.current.value = pet.res.nombre
			rCiudad.current.value = pet.res.ciudad
			setIdAsociacion(pet.res?.cfAsoc??0)
			rNombre.current.focus()
			rNombre.current.select()
	}
		async function verAsocs() {//descargo las Asociaciones
			const pet = await peticion('/asocs/?ini=0&pag=1000&orden=nombre&filtro=')
			if (pet.estado) //algún error
				return;
			setAsociaciones(pet?.res??[])
		}	
		if (props.show===0)
			return
		verAsocs()
		if (props.show > 0)
			ver()
		else if (props.show < 0) {//INSERTANDO
			rId.current.value = ''
			rNombre.current.value = ''
			rCiudad.current.value = ''
			let asoc=props.ListParams.idAsoc
			if (!perfil_admin)
				asoc=perfil_asoc
			setIdAsociacion(asoc)
			rNombre.current.focus()

		}
	}, [props.show, peticion, perfil_admin,perfil_asoc,props.ListParams])

	// guardar el registro
	async function guarda() {
		//filtros sin necesidad de peticiones
		let error=false
		//requeridos
		error|=validErr(rNombre.current.value === '',setErrNombre,loc('El nombre es requerido.'))
		error|=validErr(rCiudad.current.value === '',setErrCiudad,loc('La ciudad es requerida.'))
		error|=validErr(rAsoc.current.value === '0',setErrAsoc,loc('La asociación es requerida.'))
		if (!perfil_admin && !error)//es perfil de adm asoc, solo guardar en la asov suya
			error|=validErr(parseInt(rAsoc.current.value) !== perfil_asoc ,setErrAsoc,loc('Solo puedes guardar en tu asociación.'))
	

		if (error)//algún error
			return;
		//filtros con peticiones
		let pet
		//comprobamos que nombre no está repetido
		pet = await peticion(`/dojos/rep_nombre?nombre=${rNombre.current.value}&id=${props.show}&idAsoc=${IdAsociacion}`)
		if (!pet?.estado ?? 1)//estado OK
			if ((pet?.res?.idDojo ?? 1) > -1) {
				setErrNombre(loc('Ya existe un dojo con ese nombre en la asociación.'))
				return;
			}
		if (props.show < 0)//inserción
			pet = await peticion('/dojos/ins', {
				method: 'POST',
				json: {
					nombre: rNombre.current.value,
					ciudad: rCiudad.current.value,
					idAsoc: IdAsociacion
				}
			})
		else if (props.show > 0)//modificación
			pet = await peticion('/dojos/mod', {
				method: 'POST',
				json: {
					id: rId.current.value,
					nombre: rNombre.current.value,
					ciudad: rCiudad.current.value,
					idAsoc: IdAsociacion
				}
			})
		let id = pet?.res?.id ?? rId.current.value
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev)=>({...prev}))
		else
			navigate(`/${lang}/dojos/asoc_${props.ListParams.idAsoc}/${id}`)
	}
	//cierra el modal
	function cierra() {
		setErrNombre('')
		setErrCiudad('')
		setErrAsoc('')
		props.onHide()
	}


	// if (!perfil_admin && !perfil_adm_asoc)
	// 	return ("")
	if (props.show === 0)
		return ("")
	else
		return (
			<main className="custom-modal modalasocs">
				<div className="modal-main" >
					<div className="container_modal col-lg-4 col-md-12 d-flex">
						<div className="nuevazona col-xs-12">
							<div className="col-xs-12 d-flex mb-3">
								<h4 className="col-xs-12">
									<img src={logomas} alt='cerrar' title='cerrar' /> {props.show < 0 ? loc('Añadir nuevo dojo') : loc('Modificar dojo')}
								</h4>
								<button type="button" onClick={cierra} className="btn" alt="cerrar"><span><img src={close2} alt='cerrar' title='cerrar' /></span></button>
							</div>
							<div className="col-xs-12 continput">
								<input ref={rId} type='hidden' />
								<p>{loc('Nombre')}:</p>
								<input ref={rNombre}
									onChange={() => setErrNombre('')}
									placeholder={loc('Nombre del dojo')} />
								<p className='error'>{ErrNombre}</p>
							</div>
							<div className="col-xs-12 continput">
								<p>{loc('Ciudad')}:</p>
								<input ref={rCiudad}
									onChange={() => setErrCiudad('')}
									placeholder={loc('Ciudad donde está el dojo')} />
								<p className='error'>{ErrCiudad}</p>
							</div>
							<div className="col-xs-12 continput">
								<p>{loc('Asociación')}:</p>
								<select 
									ref={rAsoc}
									value={IdAsociacion}
									onChange={() => {setIdAsociacion(rAsoc.current.value)}}>
									<option value={0}>{loc('Seleccionar asociación')}</option>
									{Asociaciones.map(
										(elem)=>(
											<option value={elem.idAsoc} key={elem.idAsoc}>{elem.nombre} ({elem.zona})</option>
										))
									}
								</select>
								<p className='error'>{ErrAsoc}</p>
							</div>
							<div className="col-xs-12 contbutton">
								<button type="button" onClick={guarda} className="btn btnguardar ">{loc('Guardar')} <span><img src={save} alt='guardar' title='guardar' /></span></button>
								<p className='error'></p>
							</div>
						</div>
					</div>
				</div>
			</main>

		);
}

export default DojosMod;